.page-estate {
	&__intro {
		margin-bottom: vm(50);
		padding: vm(111) vm(25);
		min-height: vm(928);
		background: url("../images/estate-intro.jpg") 25% 50% no-repeat;
		background-size: cover;

		&__title {
			margin: 0;
			font-weight: 300;
			font-size: vm(26);
			line-height: vm(31);
			letter-spacing: -0.02em;
			color: rgba(18, 18, 18, 0.6);

			span {
				color: $color-cod-gray;
			}

			@media (min-width: $sm) {
				max-width: vf(752);
				font-size: vf(74);
				line-height: vf(89);
			}
		}

		&__text {
			margin: vm(59) 0 0;
			font-weight: 300;
			font-size: vm(16);
			line-height: vm(26);
			letter-spacing: 0.01em;

			@media (min-width: $sm) {
				margin: vf(314) 0 0 vf(288);
				max-width: vf(1072);
				font-size: vf(44);
				line-height: vf(70);
			}
		}

		@media (min-width: $sm) {
			margin-bottom: vf(273);
			padding: vf(323) vf(136);
			min-height: vf(2572);
			background-position: 50% 50%;
		}
	}

	&__video {
		margin-bottom: vm(70);
		padding: 0 vm(25);

		.page-index__video {
			margin: 0 0 vm(24);
			padding: 0;
			width: 100%;

			&__image {
				position: relative;
				left: auto;
				top: auto;
				margin: 0;
				width: 100%;

				@media (max-width: $sm - 1) {
					height: vm(320);
					object-fit: cover;
				}
			}

			@media (min-width: $sm) {
				flex-shrink: 0;
				margin: 0 vf(101) 0 0;
				width: vf(594);
			}
		}

		&__content {
			width: 100%;

			@media (min-width: $sm) {
				padding-top: vf(74);
			}
		}

		&__label {
			margin-bottom: vm(16);
			font-weight: 500;
			font-size: vm(10);
			line-height: vm(16);
			letter-spacing: 0.2em;
			text-transform: uppercase;
			color: $color-heath;

			@media (min-width: $sm) {
				margin-bottom: vf(48);
				font-size: vf(14);
				line-height: vf(22);
			}
		}

		&__title {
			font-size: vm(22);
			line-height: vm(29);
			letter-spacing: -0.03em;
			color: rgba(18, 18, 18, 0.6);

			span {
				color: $color-cod-gray;
			}

			@media (min-width: $sm) {
				font-size: vf(60);
				line-height: vf(72);
			}
		}

		@media (min-width: $sm) {
			display: flex;
			margin-bottom: vf(141);
			padding: 0 vf(259) 0 vf(402);
		}
	}

	&__slogan {
		margin-bottom: vm(49);
		padding: 0 vm(25);
		font-size: vm(34);
		line-height: vm(37);
		letter-spacing: -0.03em;
		color: rgba(14, 46, 58, 0.4);

		span {
			color: #0e2e3a;
		}

		@media (min-width: $sm) {
			margin: 0 0 0 vf(402);
			padding: 0;
			max-width: vf(1017);
			font-size: vf(94);
			line-height: vf(103);
		}
	}

	&__work {
		position: relative;
		z-index: 5;
		margin-bottom: vm(90);
		padding: vm(151) vm(25) 0;
		background: url("../images/estate-work.jpg") 10% 0 no-repeat;
		background-size: cover;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			z-index: -2;
			width: 100%;
			height: vm(227);
			background:
				linear-gradient(
					180deg,
					#f9f9f9 0%,
					rgba(249, 249, 249, 0.9914) 6.67%,
					rgba(249, 249, 249, 0.96449) 13.33%,
					rgba(249, 249, 249, 0.91834) 20%,
					rgba(249, 249, 249, 0.85258) 26.67%,
					rgba(249, 249, 249, 0.76822) 33.33%,
					rgba(249, 249, 249, 0.66811) 40%,
					rgba(249, 249, 249, 0.5573) 46.67%,
					rgba(249, 249, 249, 0.44269) 53.33%,
					rgba(249, 249, 249, 0.33188) 60%,
					rgba(249, 249, 249, 0.23177) 66.67%,
					rgba(249, 249, 249, 0.14741) 73.33%,
					rgba(249, 249, 249, 0.08165) 80%,
					rgba(249, 249, 249, 0.03551) 86.67%,
					rgba(249, 249, 249, 0.00864) 93.33%,
					rgba(249, 249, 249, 0) 100%
				);

			@media (min-width: $sm) {
				height: vf(886);
			}
		}

		&::after {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			z-index: -2;
			width: 100%;
			height: vm(227);
			background:
				linear-gradient(
					0deg,
					#f9f9f9 0%,
					rgba(249, 249, 249, 0.99135) 6.67%,
					rgba(249, 249, 249, 0.96449) 13.33%,
					rgba(249, 249, 249, 0.91834) 20%,
					rgba(249, 249, 249, 0.85258) 26.67%,
					rgba(249, 249, 249, 0.76822) 33.33%,
					rgba(249, 249, 249, 0.66811) 40%,
					rgba(249, 249, 249, 0.5573) 46.67%,
					rgba(249, 249, 249, 0.44269) 53.33%,
					rgba(249, 249, 249, 0.33188) 60%,
					rgba(249, 249, 249, 0.23177) 66.67%,
					rgba(249, 249, 249, 0.14741) 73.33%,
					rgba(249, 249, 249, 0.08165) 80%,
					rgba(249, 249, 249, 0.03551) 86.67%,
					rgba(249, 249, 249, 0.00864) 93.33%,
					rgba(249, 249, 249, 0) 100%
				);

			@media (min-width: $sm) {
				height: vf(886);
			}
		}

		&__trigger {
			position: absolute;
			top: 0;
		}

		&__noise {
			position: absolute;
			left: 0;
			top: 0;
			z-index: -1;
			width: 100%;
			height: 100%;
			background: url("../images/noise.gif") 50% 50%;
			opacity: 0.05;
		}

		&__item {
			font-size: vm(32);
			line-height: vm(35);
			letter-spacing: -0.03em;
			color: rgba(14, 46, 58, 0.4);

			span {
				color: #0e2e3a;
			}

			&--white {
				color: $color-white;
			}

			&--gold {
				color: $color-laser;
			}

			& + & {
				margin-top: vm(60);
				font-size: vm(22);
				line-height: vm(29);
			}

			@media (min-width: $sm) {
				max-width: vf(984);
				font-size: vf(94);
				line-height: vf(103);

				& + & {
					margin-top: vf(480);
					font-size: vf(84);
					line-height: vf(101);
				}

				& + & + & {
					margin-top: vf(445);
					font-size: vf(80);
					line-height: vf(96);
				}

				& + & + & + & {
					margin-top: vf(410);
					font-size: vf(76);
					line-height: vf(91);
				}

				& + & + & + & + & {
					margin-top: vf(375);
					font-size: vf(66);
					line-height: vf(79);
				}

				& + & + & + & + & + & {
					margin-top: vf(340);
					font-size: vf(54);
					line-height: vf(70);
				}

				& + & + & + & + & + & + & {
					margin-top: vf(305);
					font-size: vf(48);
					line-height: vf(58);
				}

				& + & + & + & + & + & + & + & {
					margin-top: vf(270);
					max-width: vf(726);
					font-size: vf(42);
					line-height: vf(59);
				}

				& + & + & + & + & + & + & + & + & {
					margin-top: vf(235);
					max-width: vf(739);
					font-size: vf(36);
					line-height: vf(54);
				}

				& + & + & + & + & + & + & + & + & + & {
					margin-top: vf(200);
					max-width: vf(900);
					font-size: vf(32);
					line-height: vf(38);
				}
			}
		}

		@media (min-width: $sm) {
			margin-bottom: vf(417);
			padding: vf(677) 0 vf(500) vf(118);
			background-position: 50% 0;
			background-size: 100% auto;
		}
	}

	&__protecting {
		position: relative;
		z-index: 5;
		padding: vm(20) vm(25) 0;
		min-height: vm(1009);
		background: url("../images/estate-protecting.jpg") 32% 0 no-repeat;
		background-size: vm(1250) auto;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			z-index: -1;
			width: 100%;
			height: vm(484);
			background:
				linear-gradient(
					180deg,
					#f9f9f9 0%,
					rgba(249, 249, 249, 0.9914) 6.67%,
					rgba(249, 249, 249, 0.96449) 13.33%,
					rgba(249, 249, 249, 0.91834) 20%,
					rgba(249, 249, 249, 0.85258) 26.67%,
					rgba(249, 249, 249, 0.76822) 33.33%,
					rgba(249, 249, 249, 0.66811) 40%,
					rgba(249, 249, 249, 0.5573) 46.67%,
					rgba(249, 249, 249, 0.44269) 53.33%,
					rgba(249, 249, 249, 0.33188) 60%,
					rgba(249, 249, 249, 0.23177) 66.67%,
					rgba(249, 249, 249, 0.14741) 73.33%,
					rgba(249, 249, 249, 0.08165) 80%,
					rgba(249, 249, 249, 0.03551) 86.67%,
					rgba(249, 249, 249, 0.00864) 93.33%,
					rgba(249, 249, 249, 0) 100%
				);

			@media (min-width: $sm) {
				height: vf(886);
			}
		}

		&__noise {
			position: absolute;
			left: 0;
			top: 0;
			z-index: -1;
			width: 100%;
			height: 100%;
			background: url("../images/noise.gif") 50% 50%;
			opacity: 0.05;
		}

		&__title {
			margin: 0 0 vm(66);
			font-weight: 300;
			font-size: vm(30);
			line-height: vm(36);
			letter-spacing: -0.02em;

			@media (min-width: $sm) {
				margin: 0 0 vf(222);
				max-width: vf(775);
				font-size: vf(90);
				line-height: vf(108);
			}
		}

		&__item {
			&__title {
				margin: 0 0 vm(60);
				font-weight: 300;
				font-size: vm(24);
				line-height: vm(29);
				letter-spacing: -0.02em;

				@media (min-width: $sm) {
					margin: 0 0 vf(46);
					font-size: vf(56);
					line-height: vf(67);
				}
			}

			&__text {
				font-weight: 300;
				font-size: vm(14);
				line-height: vm(22);
				letter-spacing: 0.01em;
				opacity: 0.9;

				@media (min-width: $sm) {
					font-size: vf(29);
					line-height: vf(46);
				}
			}

			@media (min-width: $sm) {
				max-width: vf(730);

				&--2 {
					margin-left: vf(212);
					max-width: vf(614);
				}
			}
		}

		&__item + &__item {
			margin-top: vm(60);

			@media (min-width: $sm) {
				margin-top: vf(370);
			}
		}

		@media (min-width: $sm) {
			padding: vf(20) 0 0 vf(640);
			min-height: vf(2374);
			background-position: 50% 50%;
			background-size: cover;
		}
	}

	&__we {
		position: relative;
		z-index: 5;
		margin-top: vm(70);
		padding: 0 vm(25);

		&__word {
			font-weight: 300;
			font-size: vm(48);
			line-height: vm(54);
			letter-spacing: -0.04em;
			opacity: 0.2;

			@media (min-width: $sm) {
				flex-shrink: 0;
				margin: 0 vf(41) 0 0;
				width: vf(236);
				font-size: vf(158);
				line-height: vf(177);
			}
		}

		&__list {
			@media (min-width: $sm) {
				padding-top: vf(670);
				width: 100%;
			}
		}

		&__item {
			&__title {
				font-weight: 300;
				font-size: vm(48);
				line-height: vm(54);
				letter-spacing: -0.04em;

				@media (min-width: $sm) {
					font-size: vf(158);
					line-height: vf(177);
				}
			}

			&__text {
				margin-top: vm(21);
				font-size: vm(16);
				line-height: vm(29);
				letter-spacing: 0.02em;
				opacity: 0.85;

				@media (min-width: $sm) {
					margin-top: vf(66);
					max-width: vf(857);
					font-size: vf(22);
					line-height: vf(40);
				}
			}

			@media (min-width: $sm) {
				max-width: vf(1000);
			}
		}

		&__item + &__item {
			margin-top: vm(75);

			@media (min-width: $sm) {
				margin-top: vf(145);
			}
		}

		@media (min-width: $sm) {
			display: flex;
			margin-top: vf(290);
			padding: 0 0 0 vf(190);
		}
	}
}
