@function vw($value, $base: 1600) {
	@return $value / $base * 100vw;
}

@function vf($value, $base: 1920) {
	@return $value / $base * 100vw;
}

@function vm($value, $base: 320) {
	@return $value / $base * 100vw;
}
