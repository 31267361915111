.footer {
	position: relative;

	&__trigger {
		position: absolute;
		top: 0;
	}

	&__contacts {
		list-style-type: none;
		margin: 0;
		border-top: solid 1px rgba($color-white, 0.1);
		padding: vm(40) vm(48) vm(72);
		width: 100%;
		text-align: center;

		li {
			+ li {
				margin-top: vm(56);

				@media (min-width: $sm) {
					margin-top: 0;
				}
			}

			@media (min-width: $sm) {
				width: 33.33334%;
			}
		}

		&__key {
			margin-bottom: vm(12);
			font-family: $font-family-halvar;
			font-weight: 300;
			font-size: vm(20);
			line-height: vm(24);
			letter-spacing: 0.05em;
			text-transform: uppercase;
			color: $color-white;

			a {
				text-decoration: none;
				color: $color-white;
				transition: 0.25s;

				&:hover {
					opacity: 0.7;
				}

				.is-page-light & {
					color: $color-heath;
				}
			}

			@media (min-width: $sm) {
				margin-bottom: vw(17);
				font-size: vw(27);
				line-height: vw(32);
				color: $color-heath;
			}
		}

		&__value {
			margin-top: vm(12);
			font-size: vm(12.4);
			line-height: vm(17);
			color: $color-white;
			opacity: 0.5;

			.is-page-light & {
				color: #1c1d20;
			}

			@media (min-width: $sm) {
				margin-top: vw(14, 1920);
				font-size: vw(17);
				line-height: vw(26);
			}
		}

		.is-page-light & {
			border-top-color: rgba(#1c1d20, 0.1);
		}

		@media (min-width: $sm) {
			display: flex;
			padding: vw(80) vw(88) vw(80) vw(50);
		}
	}

	@media (min-width: $sm) {
		display: flex;
		align-items: flex-end;
		height: 100vh;
	}
}
