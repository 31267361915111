*,
*::before,
*::after {
	box-sizing: inherit;
}

html {
	box-sizing: border-box;
}

body {
	font-family: $font-family-graphik;
	color: $color-white;
	background: $color-diesel;
	// background: $color-diesel url("../images/noise.png") 50% 50%;

	.is-page-light & {
		color: #131313;
		background: #f9f9f9;
	}

	.is-page-digital & {
		background: #000313;
	}

	.is-page-handling & {
		background: #01020c;
	}
}

a {
	&:focus {
		outline: none;
	}
}

img {
	vertical-align: middle;
	max-width: 100%;
}

.main {
	// overflow: hidden;
}
