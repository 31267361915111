@font-face {
	src: url("../fonts/graphik/GraphikLCG-Light.woff2") format("woff2"), url("../fonts/graphik/GraphikLCG-Light.woff") format("woff");
	font-family: "Graphik";
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	src: url("../fonts/graphik/GraphikLCG-Regular.woff2") format("woff2"), url("../fonts/graphik/GraphikLCG-Regular.woff") format("woff");
	font-family: "Graphik";
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	src: url("../fonts/graphik/GraphikLCG-Medium.woff2") format("woff2"), url("../fonts/graphik/GraphikLCG-Medium.woff") format("woff");
	font-family: "Graphik";
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	src: url("../fonts/halvar/HalvarBreit-Lt.woff2") format("woff2"), url("../fonts/halvar/HalvarBreit-Lt.woff") format("woff");
	font-family: "Halvar";
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	src: url("../fonts/halvar/HalvarBreit-Rg.woff2") format("woff2"), url("../fonts/halvar/HalvarBreit-Rg.woff") format("woff");
	font-family: "Halvar";
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	src: url("../fonts/halvar/HalvarBreit-Md.woff2") format("woff2"), url("../fonts/halvar/HalvarBreit-Md.woff") format("woff");
	font-family: "Halvar";
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	src: url("../fonts/halvar/HalvarBreit-Bd.woff2") format("woff2"), url("../fonts/halvar/HalvarBreit-Bd.woff") format("woff");
	font-family: "Halvar";
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	src: url("../fonts/jetbrainsmono/JetBrainsMono-Regular.woff2") format("woff2"), url("../fonts/halvar/JetBrainsMono-Regular.woff") format("woff");
	font-family: "JetBrainsMono";
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	src: url("../fonts/jetbrainsmono/JetBrainsMono-Medium.woff2") format("woff2"), url("../fonts/halvar/jetbrainsmono-Medium.woff") format("woff");
	font-family: "JetBrainsMono";
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}
