.page-404 {
	padding: vm(152) vm(25) 0;
	color: $color-white;

	&__number {
		position: relative;
		z-index: 5;
		margin-bottom: vm(27);
		font-family: $font-family-halvar;
		font-weight: 300;
		font-size: vm(108);
		line-height: vm(108);
		letter-spacing: -0.03em;
		text-align: center;
		text-transform: uppercase;

		svg {
			position: absolute;
			left: 50%;
			top: 50%;
			z-index: -1;
			width: vm(227);
			height: vm(119);
			fill: none;
			transform: translate(-50%, -50%);

			@media (min-width: $sm) {
				width: vf(381);
				height: vf(202);
			}
		}

		@media (min-width: $sm) {
			margin-bottom: vf(46);
			font-size: vf(180);
			line-height: vf(180);
		}
	}

	&__title {
		margin: 0 0 vm(75);
		font-weight: 400;
		font-size: vm(16);
		line-height: vm(22);
		text-align: center;

		@media (min-width: $sm) {
			margin: 0 0 vf(125);
			font-size: vf(26);
			line-height: vf(36);
		}
	}

	&__text {
		margin: 0 auto;
		font-weight: 300;
		font-size: vm(28);
		line-height: vm(42);
		text-align: center;
		color: rgba(255, 255, 255, 0.3);

		a {
			position: relative;
			z-index: 5;
			border-bottom: solid 1px rgba($color-white, 0.2);
			text-decoration: none;
			color: $color-white;

			svg {
				position: absolute;
				left: 0;
				top: vw(-55);
				z-index: -1;
				width: vw(359);
				height: vw(171);
				fill: none;
				stroke-dasharray: 990;
				stroke-dashoffset: 990;
				transition: 0.75s;
				pointer-events: none;

				@media (max-width: $sm - 1) {
					display: none;
				}
			}

			&:hover {
				svg {
					stroke-dashoffset: 0;
				}
			}

			@media (max-width: $sm - 1) {
				border-bottom-width: vm(2);
				padding-bottom: vm(3);
			}
		}

		@media (min-width: $sm) {
			max-width: vf(1100);
			font-size: vf(58);
			line-height: vf(75);
		}
	}

	@media (min-width: $sm) {
		padding: vf(197) 0 0;
	}
}
