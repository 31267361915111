.page-service {
	padding: vm(111) vm(25) 0;
	color: $color-white;

	&__title {
		position: relative;
		z-index: 5;
		margin: 0 0 vm(40);
		font-family: $font-family-halvar;
		font-weight: 300;
		font-size: vm(32);
		line-height: vm(45);
		letter-spacing: -0.07em;
		text-align: center;
		text-transform: uppercase;

		svg {
			position: absolute;
			left: 50%;
			top: 50%;
			z-index: -1;
			width: vm(196);
			height: vm(84);
			fill: none;
			transform: translate(-51%, -30%);

			@media (min-width: $sm) {
				width: vf(1082);
				height: vf(462);
			}
		}

		@media (min-width: $sm) {
			margin: 0 0 vf(230);
			padding: 0 vf(200);
			font-size: vf(170);
			line-height: vf(204);
		}
	}

	&__padding {
		padding: 0;

		@media (min-width: $sm) {
			padding: 0 vf(280);
		}
	}

	&__text {
		margin-bottom: vm(50);
		font-weight: 300;
		font-size: vm(18);
		line-height: vm(29);
		letter-spacing: 0.03em;

		@media (min-width: $sm) {
			margin-bottom: vf(276);
			font-size: vf(44);
			line-height: vf(70);
		}
	}

	.page-index__video {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: vm(82);
		background: none;

		&__content {
			flex-shrink: 0;

			@media (min-width: $sm) {
				margin-right: vf(92);
				width: vf(342);
			}
		}

		&__label {
			font-size: vm(10);
			color: rgba($color-white, 0.5);

			@media (min-width: $sm) {
				font-size: vf(12);
			}
		}

		&__text {
			color: rgba($color-white, 0.5);

			span {
				display: block;
				color: $color-white;
			}

			@media (min-width: $sm) {
				font-size: vf(24);
				line-height: vf(38);
			}
		}

		&__image {
			position: relative;
			left: 0;
			top: 0;
			order: -1;
			margin: 0 vm(-45) vm(30);
			height: vm(320);

			@media (min-width: $sm) {
				order: 0;
				margin: 0;
				width: vf(684);
				height: auto;
			}
		}

		@media (min-width: $sm) {
			margin-bottom: vf(359);
		}
	}

	&__appeal {
		margin-bottom: vm(92);

		&__content {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-end;
			margin-bottom: vm(34);

			@media (min-width: $sm) {
				flex-wrap: nowrap;
				margin-bottom: vf(145);
			}
		}

		.page-service__subtitle {
			margin: 0 0 vm(20);

			@media (min-width: $sm) {
				margin: 0;
			}
		}

		&__text {
			flex-shrink: 0;
			margin: 0;
			width: 100%;
			font-weight: 300;
			font-size: vm(14);
			line-height: vm(22);
			letter-spacing: 0.04em;

			@media (min-width: $sm) {
				margin: 0 0 vf(16) auto;
				width: vf(419);
				font-size: vf(18);
				line-height: vf(29);
			}
		}

		&__list {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			&__item {
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px solid #373737;
				border-radius: 50%;
				padding: vm(19);
				width: vm(203);
				height: vm(203);
				font-size: vm(15);
				line-height: vm(21);
				letter-spacing: 0.01em;
				text-align: center;

				&:last-child {
					border-color: $color-laser;
					color: #0e0f11;
					background: $color-laser;
				}

				@media (min-width: $sm) {
					padding: vf(45);
					width: vf(350);
					height: vf(350);
					font-size: vf(24);
					line-height: vf(34);
				}
			}

			&__item + &__item {
				margin: vm(-40) 0 0;

				@media (min-width: $sm) {
					margin: 0 0 0 vf(-23);
				}
			}

			@media (min-width: $sm) {
				justify-content: normal;
			}
		}

		@media (min-width: $sm) {
			margin-bottom: vf(394);
		}
	}

	&__subtitle {
		margin: 0 0 vm(60);
		max-width: vm(260);
		font-weight: 300;
		font-size: vm(32);
		line-height: vm(38);
		letter-spacing: -0.02em;

		@media (min-width: $sm) {
			margin: 0 0 vf(280);
			max-width: vf(1187);
			font-size: vf(90);
			line-height: vf(108);
		}
	}

	&__advise {
		position: relative;
		width: vm(270);

		.swiper-container {
			overflow: visible;

			@media (min-width: $sm) {
				margin: 0;
				width: vf(945);
			}
		}

		.swiper-slide-active {
			.page-service__advise__title {
				opacity: 1;
			}

			.page-service__advise__text {
				opacity: 0.9;
				visibility: visible;
			}
		}

		&__item {
			position: relative;
			display: flex;
			flex-direction: column;

			@media (min-width: $sm) {
				margin-right: vf(140);
			}
		}

		&__title {
			margin-bottom: vm(30);
			font-size: vm(22);
			line-height: vm(26);
			letter-spacing: -0.01em;
			color: $color-white;
			opacity: 0.3;
			transition: 0.25s;

			@media (min-width: $sm) {
				margin-bottom: vf(53);
				min-height: vf(216);
				font-size: vf(60);
				line-height: vf(72);
			}
		}

		&__text {
			margin-top: auto;
			font-weight: 300;
			font-size: vm(15);
			line-height: vm(24);
			letter-spacing: 0.01em;
			color: $color-white;
			opacity: 0;
			visibility: hidden;
			transition: 0.25s;

			@media (min-width: $sm) {
				max-width: vf(797);
				font-size: vf(29);
				line-height: vf(46);
			}
		}

		&__arrow {
			position: absolute;
			z-index: 5;
			margin: vf(-75) 0 0 vf(-75);
			outline: none;
			border: none;
			border-radius: 50%;
			padding: 0;
			width: vf(150);
			height: vf(150);
			background: $color-laser;
			pointer-events: none;

			svg {
				position: absolute;
				left: 50%;
				top: 50%;
				width: vf(28);
				height: vf(15);
				stroke: #0e0f11;
				stroke-width: 2px;
				transform: translate(-50%, -50%) rotate(-180deg);
				transition: transform 0.25s;
			}

			&.is-right {
				svg {
					transform: translate(-50%, -50%);
				}
			}

			@media (max-width: $sm - 1) {
				display: none;
			}
		}

		&__prev,
		&__next {
			position: absolute;
			top: 0;
			z-index: 5;
			outline: none;
			border: none;
			padding: 0;
			width: 50vw;
			height: 100%;
			background: none;

			@media (max-width: $sm - 1) {
				display: none;
			}
		}

		&__prev {
			left: 0;
		}

		&__next {
			left: 50%;

			&__arrow {
				position: absolute;
				display: none;
				margin: vf(-75) 0 0 vf(-75);
				outline: none;
				border: none;
				border-radius: 50%;
				padding: 0;
				width: vf(150);
				height: vf(150);
				background: $color-laser;
				pointer-events: none;

				svg {
					position: absolute;
					left: 50%;
					top: 50%;
					width: vf(28);
					height: vf(15);
					stroke: #0e0f11;
					stroke-width: 2px;
					transform: translate(-50%, -50%);
				}
			}
		}

		@media (min-width: $sm) {
			margin: 0 vf(-118);
			padding: 0 0 0 vf(398);
			width: auto;
		}
	}

	@media (min-width: $sm) {
		padding: vf(333) vf(118) 0;
	}
}
