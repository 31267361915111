.page-index {
	.mCSB_inside > .mCSB_container {
		margin-right: 0;
	}

	&__content {
		position: relative;
	}

	&__trigger {
		@media (min-width: $sm) {
			position: absolute;
			left: 0;
			top: 50vh;
		}
	}

	&__screen {
		position: relative;

		&#intro {
			min-height: 100vh;
		}

		&:nth-child(2) {
			min-height: var(--vh);
		}

		@media (min-width: $sm) {
			position: static;
			min-height: 100vh;

			&:nth-child(2) {
				min-height: 100vh;
			}
		}
	}

	&__slogan-name {
		margin: 0 0 vm(27);
		padding: vm(117) vm(20) 0;
		font-family: $font-family-halvar;
		font-size: vm(10);
		line-height: vm(18);
		letter-spacing: 0.16em;
		text-transform: uppercase;
		color: rgba($color-white, 0.5);

		.is-state-preloader & {
			opacity: 0;
		}

		@media (min-width: $sm) {
			position: fixed;
			left: vw(37);
			right: 0;
			bottom: vw(453);
			margin: 0;
			padding: 0;
			font-size: vw(11);
			line-height: vw(18);
		}
	}

	&__slogan-title {
		padding: 0 vm(20);
		font-family: $font-family-halvar;
		font-weight: 300;
		font-size: vm(27);
		line-height: vm(34);
		letter-spacing: -0.03em;
		text-transform: uppercase;
		color: rgba($color-white, 0.2);

		&__text {
			display: block;
			overflow: hidden;

			span {
				display: block;
			}
		}

		@media (min-width: $sm) {
			position: fixed;
			left: vw(26);
			right: 0;
			bottom: vw(322);
			display: none;
			padding: 0;
			font-size: vw(96);
			line-height: 100%;
		}
	}

	&__slogan-text {
		padding: 0 vm(20) vm(150);
		font-family: $font-family-halvar;
		font-weight: 300;
		font-size: vm(27);
		line-height: vm(34);
		letter-spacing: -0.03em;
		text-transform: uppercase;
		color: $color-white;
		pointer-events: none;

		&__text {
			display: block;
			overflow: hidden;

			span {
				display: block;
			}

			@media (min-width: $sm) {
				white-space: nowrap;
			}
		}

		@media (min-width: $sm) {
			position: fixed;
			left: vw(26);
			right: 0;
			bottom: vw(130);
			z-index: 10;
			padding: 0;
			font-size: vw(90);
			line-height: 100%;
		}
	}

	&__start-arrow {
		position: absolute;
		left: vm(175);
		top: vm(295);
		display: flex;
		align-items: center;
		justify-content: center;
		outline: none;
		border: none;
		border-radius: 50%;
		width: vm(87);
		height: vm(87);
		background: $color-laser;

		svg {
			width: vm(10);
			height: vm(16);

			@media (min-width: $sm) {
				width: vw(18);
				height: vw(34);
			}
		}

		@media (min-width: $sm) {
			position: fixed;
			left: vw(1221);
			top: auto;
			bottom: vw(181);
			display: none;
			width: vw(185);
			height: vw(185);
		}
	}

	&__description {
		position: relative;
		margin: 0;
		padding: vm(44) vm(20) vm(41);
		background: $color-alabaster;

		&-container {
			@media (min-width: $sm) {
				height: vw(1042, 1920);
			}
		}

		&__title {
			position: relative;
			z-index: 5;
			margin: 0 0 vm(42);
			font-family: $font-family-halvar;
			font-weight: 300;
			font-size: vm(40);
			line-height: vm(48);
			letter-spacing: -0.03em;
			text-transform: uppercase;
			color: #4c0f28;

			svg {
				position: absolute;
				left: vm(15);
				top: vm(25);
				z-index: -1;
				width: vm(185);
				height: vm(100);
				fill: none;
				stroke-dasharray: 1381;

				@media (min-width: $sm) {
					left: vw(656);
					top: vw(-80);
					width: vw(567);
					height: vw(224);
				}

				@media (max-width: $sm - 1) {
					stroke-dashoffset: 1381;
					transition: 1s;
				}
			}

			&__text {
				overflow: hidden;

				&::after {
					content: "";
					display: inline-block;
					margin-left: vm(10);
					border-radius: 50%;
					width: vm(7);
					height: vm(7);
					background: $color-laser;
				}

				span {
					@media (min-width: $sm) {
						display: block;
					}
				}

				@media (min-width: $sm) {
					&::after {
						display: none;
					}
				}
			}

			&.is-absolute {
				@media (min-width: $sm) {
					position: absolute;
					left: 0;
					top: 0;
					color: $color-coffee-bean;
					opacity: 1;
					transform: none !important;
				}
			}

			&.is-animated {
				svg {
					@media (max-width: $sm - 1) {
						stroke-dashoffset: 0;
					}
				}
			}

			.is-state-preloader & {
				opacity: 0;
			}

			@media (min-width: $sm) {
				position: fixed;
				left: vw(26);
				bottom: vw(322);
				margin: 0;
				font-size: vw(100);
				line-height: 100%;
				color: $color-white;
				opacity: 0.2;
				transform: scale(0.9);
				transform-origin: 0% 100%;
			}
		}

		&__dot {
			position: fixed;
			left: vw(1221);
			bottom: vw(181);
			display: flex;
			align-items: center;
			justify-content: center;
			outline: none;
			border: none;
			border-radius: 50%;
			padding: vw(80);
			width: vw(185);
			height: vw(185);
			background: $color-laser;

			svg {
				width: vw(18);
				height: vw(34);
				max-width: 100%;
				max-height: 100%;
			}

			&:hover {
				opacity: 0.7 !important;
				transition: 0.25s;
			}

			&.is-absolute {
				position: absolute;
				left: vw(1257);
				top: vw(76);
				padding: vw(6.5);
				width: vw(13);
				height: vw(13);
				transform: none !important;
				pointer-events: none;

				svg {
					display: none;
				}
			}

			.is-state-preloader & {
				opacity: 0;
			}

			@media (max-width: $sm - 1) {
				display: none;
			}
		}

		&__text {
			font-weight: 300;
			font-size: vm(16);
			line-height: vm(26);
			letter-spacing: 0.02em;
			color: #121212;

			&__col {
				@media (min-width: $sm) {
					width: vw(585);
				}
			}

			&__col + &__col {
				margin-top: vm(32);

				@media (min-width: $sm) {
					margin: 0 0 0 vw(60);
				}
			}

			@media (min-width: $sm) {
				display: flex;
				font-size: vw(24);
				line-height: vw(38);
			}
		}

		@media (min-width: $sm) {
			margin: 0 0 0 vw(125);
			padding: vw(181) 0 0 0;
			background: transparent;
		}
	}

	&__video {
		position: relative;
		z-index: 7;
		padding: 0 vm(20) vm(86);
		background: $color-alabaster;

		&__content {
			position: relative;
			z-index: 5;
			width: vm(220);

			@media (min-width: $sm) {
				width: vw(260);
				pointer-events: none;
			}
		}

		&__image {
			position: relative;
			overflow: hidden;
			margin: 0 vm(-20) vm(25);
			cursor: none;

			@media (min-width: $sm) {
				position: absolute;
				left: vw(305);
				top: vw(390);
				margin: 0;
				width: vw(900);
			}
		}

		&__label {
			margin-bottom: vm(14);
			font-size: vm(9);
			letter-spacing: 0.2em;
			text-transform: uppercase;
			color: $color-cod-gray;
			opacity: 0.4;

			@media (min-width: $sm) {
				margin-bottom: vw(26);
				font-size: vw(10);
			}
		}

		&__text {
			font-weight: 300;
			font-size: vm(14);
			line-height: vm(22);
			color: $color-cod-gray;

			@media (min-width: $sm) {
				font-size: vw(17);
				line-height: vw(27);
			}
		}

		&__button {
			position: absolute;
			left: 50%;
			top: 50%;
			margin: vm(-43) 0 0 vm(-43);
			outline: none;
			border: none;
			border-radius: 50%;
			width: vm(86);
			height: vm(86);
			background: $color-heath;
			cursor: none;

			&::before {
				content: "";
				position: absolute;
				left: 50%;
				top: 50%;
				border-top: solid vm(5) transparent;
				border-bottom: solid vm(5) transparent;
				border-left: solid vm(8) $color-white;
				transform: translate(-50%, -50%);

				@media (min-width: $sm) {
					border-top-width: vw(8);
					border-bottom-width: vw(8);
					border-left-width: vw(11);
				}
			}

			@media (min-width: $sm) {
				margin: vw(-65) 0 0 vw(-65);
				width: vw(130);
				height: vw(130);
			}
		}

		@media (min-width: $sm) {
			margin: 0;
			padding: vw(715) 0 0 vw(180);
			background: transparent;
		}
	}

	&__articles {
		position: relative;
		z-index: 5;

		&-container {
			padding: vm(49) vm(20) vm(54);

			@media (min-width: $sm) {
				margin-top: vw(500);
				padding: 0 0 0 vw(113);
				height: vw(1700);
			}
		}

		&__small-dot {
			position: absolute;
			left: 50%;
			top: 50%;
			width: vw(660);
			height: vw(380);
			transform: translate(-50%, -50%);

			&::before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				border-radius: 50%;
				width: vw(13);
				height: vw(13);
				background: $color-laser;
			}

			@media (max-width: $sm - 1) {
				display: none;
			}
		}
	}

	&__article {
		margin-bottom: vm(60);
		color: $color-white;

		&__title {
			position: relative;
			z-index: 5;
			font-family: $font-family-halvar;
			font-weight: 300;
			font-size: vm(31);
			line-height: vm(40);
			letter-spacing: -0.03em;
			text-transform: uppercase;

			&::after {
				content: "";
				display: inline-block;
				margin-left: vm(-5);
				border-radius: 50%;
				width: vm(7);
				height: vm(7);
				background: $color-laser;
			}

			&__shadow {
				position: absolute;
				left: vm(26);
				top: vm(26);
				z-index: -1;
				opacity: 0.05;

				@media (min-width: $sm) {
					left: vw(34);
					top: vw(32);
				}
			}

			@media (min-width: $sm) {
				width: vw(635);
				font-size: vw(73);
				line-height: vw(102);

				&::after {
					display: none;
				}
			}
		}

		&__text {
			margin-top: vm(16);
			font-weight: 300;
			font-size: vm(15);
			line-height: vm(25);
			letter-spacing: 0.02em;

			@media (min-width: $sm) {
				margin-top: vw(15);
				width: vw(450);
				font-size: vw(20);
				line-height: vw(34);
			}
		}

		&--3 &__title {
			@media (min-width: $sm) {
				width: vw(535);
			}
		}

		@media (min-width: $sm) {
			position: absolute;
			left: 0;
			top: 50%;
			z-index: 5;
			display: flex;
			justify-content: space-between;
			margin: 0;
			width: vw(1329);
			color: $color-coffee-bean;
			transform: translate(0, -50%);
		}
	}

	&--3 {
		@media (min-width: $sm) {
			transform: translate(0, -65%);
		}
	}

	&__team-image {
		position: relative;
		margin: 0;

		&__wrapper {
			@media (min-width: $sm) {
				position: absolute;
				left: 50%;
				top: 50%;
				overflow: hidden;
				width: 100vw;
				height: 100vh;
				transform: translate(-50%, -50%);
			}
		}

		&__mask {
			@media (min-width: $sm) {
				position: absolute;
				left: 50%;
				top: 50%;
				overflow: hidden;
				border-radius: 50%;
				width: vw(63);
				height: vw(63);
				background: $color-laser;
				transform: translate(-50%, -50%);
			}
		}

		&__container {
			height: vm(322);
			background: 50% 50% no-repeat;
			background-size: cover;

			@media (min-width: $sm) {
				position: absolute;
				left: 50%;
				top: 50%;
				width: 100vw;
				height: 100vh;
				transform: translate(-50%, -50%);
			}

			@media (max-width: $sm - 1) {
				opacity: 0;
				transform: translateY(vm(100));
				transition: 1s;

				&.is-animated {
					opacity: 1;
					transform: translateY(0);
				}
			}
		}

		&__shadow {
			@media (min-width: $sm) {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: #0e0f11;
				opacity: 0.5;
			}
		}

		@media (min-width: $sm) {
			margin: vw(-1700) auto 0;
			border-radius: 50%;
			width: 0;
			height: 0;
		}
	}

	&__benefit-text {
		position: relative;
		z-index: 5;
		margin: vm(-32) 0 vm(65);
		padding: 0 vm(20);
		font-family: $font-family-halvar;
		font-weight: 300;
		font-size: vm(40);
		line-height: vm(52);
		letter-spacing: -0.04em;
		text-align: right;
		text-transform: uppercase;
		color: $color-white;

		&__text {
			position: relative;
			z-index: 5;
			transform-origin: 100% 0;

			svg {
				position: absolute;
				left: vm(35);
				top: vm(82);
				z-index: -1;
				width: vm(235);
				height: vm(100);
				fill: none;
				stroke-dasharray: 1381;

				@media (min-width: $sm) {
					left: vw(325);
					top: vw(140);
					width: vw(547);
					height: vw(214);
				}

				@media (max-width: $sm - 1) {
					stroke-dashoffset: 1381;
					transition: 1s;
				}
			}

			&.is-animated {
				svg {
					@media (max-width: $sm - 1) {
						stroke-dashoffset: 0;
					}
				}
			}
		}

		@media (min-width: $sm) {
			margin: vw(-1000) vw(105) 0 auto;
			padding: 0;
			width: vw(906);
			font-size: vw(100);
			line-height: vw(110);
		}
	}

	&__history {
		position: relative;
		margin: 0;
		padding: 0 vm(20) vm(77);
		background: $color-alabaster;

		&__image {
			margin: 0 vm(-20) vm(31);
			text-align: center;

			img {
				width: 100%;
				height: vm(422);
				object-fit: cover;

				@media (min-width: $sm) {
					width: vw(680);
					height: auto;
				}
			}

			@media (min-width: $sm) {
				margin: 0 0 vw(41);
			}
		}

		&__decore {
			position: absolute;
			left: vw(-100);
			top: vw(365);
			width: vw(398);
		}

		&__text {
			font-weight: 300;
			font-size: vm(14);
			line-height: vm(24);
			letter-spacing: 0.02em;
			color: #131313;

			&__col-1 {
				@media (min-width: $sm) {
					flex-shrink: 0;
					width: vw(462);
				}
			}

			&__col-2 {
				margin-top: vm(40);

				@media (min-width: $sm) {
					flex-shrink: 0;
					margin-top: 0;
					margin-left: vw(82);
					width: vw(317);
				}
			}

			@media (min-width: $sm) {
				display: flex;
				font-size: vw(15);
				line-height: vw(26);
			}

			@media (max-width: $sm - 1) {
				&__col-1,
				&__col-2 {
					opacity: 0;
					transform: translateY(vm(100));
					transition: 1s;
				}

				&.is-animated &__col-1,
				&.is-animated &__col-2 {
					opacity: 1;
					transform: translateY(0);
				}
			}
		}

		@media (min-width: $sm) {
			margin: vw(-700) auto 0;
			padding: 0;
			width: vw(861);
			background: transparent;
		}
	}

	&__links {
		align-items: center;
		padding: vm(50) vm(20);

		&__container {
			@media (min-width: $sm) {
				width: vw(1233);
			}
		}

		&__text {
			font-weight: 300;
			font-size: vm(33);
			line-height: vm(44);

			span {
				opacity: 0.2;
			}

			a {
				position: relative;
				z-index: 5;
				text-decoration: none;
				color: inherit;

				svg {
					position: absolute;
					left: 0;
					top: vw(-74);
					z-index: -1;
					width: vw(429);
					height: vw(211);
					fill: none;
					stroke-dasharray: 990;
					stroke-dashoffset: 990;
					transition: 0.75s;
					pointer-events: none;

					@media (max-width: $sm - 1) {
						display: none;
					}
				}

				&:hover {
					svg {
						stroke-dashoffset: 0;
					}
				}

				@media (max-width: $sm - 1) {
					border-bottom: solid vm(2) rgba($color-white, 0.2);
					padding-bottom: vm(3);
				}
			}

			@media (min-width: $sm) {
				font-size: vw(73);
				line-height: vw(88);
			}

			@media (max-width: $sm - 1) {
				color: $color-white;
			}
		}

		@media (min-width: $sm) {
			display: flex;
			padding: 0 0 0 vw(46);
			height: 120vh;
		}
	}

	&__bottom-logo {
		margin: vm(119) 0 vm(170);
		text-align: center;

		svg {
			display: inline-block;
			vertical-align: middle;
		}

		&__top {
			svg {
				width: vm(85);
				height: vm(34);
				fill: $color-laser;
			}
		}

		&__bottom {
			margin-top: vm(9);

			svg {
				width: vm(148);
				height: vm(10);
				fill: $color-white;
			}
		}

		@media (min-width: $sm) {
			display: none;
		}
	}

	&__footer {
		&__contacts {
			list-style-type: none;
			margin: 0;
			border-top: solid 1px rgba($color-white, 0.2);
			padding: vm(40) vm(48) vm(72);
			width: 100%;
			text-align: center;

			li {
				+ li {
					margin-top: vm(56);

					@media (min-width: $sm) {
						margin-top: 0;
					}
				}

				@media (min-width: $sm) {
					width: 33.33334%;
				}
			}

			&__key {
				margin-bottom: vm(12);
				font-family: $font-family-halvar;
				font-weight: 300;
				font-size: vm(20);
				line-height: vm(24);
				letter-spacing: 0.05em;
				text-transform: uppercase;
				color: $color-white;

				a {
					text-decoration: none;
					color: $color-white;
					transition: 0.25s;

					&:hover {
						opacity: 0.7;
					}

					@media (min-width: $sm) {
						color: $color-heath;
					}
				}

				@media (min-width: $sm) {
					margin-bottom: vw(17);
					font-size: vw(27);
					line-height: vw(32);
					color: $color-heath;
				}
			}

			&__value {
				margin-top: vm(12);
				font-size: vm(12.4);
				line-height: vm(17);
				color: $color-white;
				opacity: 0.5;

				a {
					text-decoration: none;
					color: inherit;
					transition: 0.25s;

					&:hover {
						opacity: 0.7;
					}
				}

				@media (min-width: $sm) {
					margin-top: 0;
					font-size: vw(17);
					line-height: vw(26);
					color: #1c1d20;
				}
			}

			&__value + &__value {
				margin-top: vm(14);

				@media (min-width: $sm) {
					margin-top: vf(14);
				}
			}

			@media (min-width: $sm) {
				display: flex;
				border-top-color: rgba(#1c1d20, 0.1);
				padding: vw(80) vw(88) vw(80) vw(50);
			}
		}

		@media (min-width: $sm) {
			display: flex;
			align-items: flex-end;
			height: 100vh;
		}
	}

	&__ticker {
		position: fixed;
		left: 0;
		bottom: vw(40);
		z-index: 10;
		width: 100%;
		font-family: $font-family-halvar;
		font-weight: 300;
		font-size: vw(10.8);
		line-height: vw(17);
		letter-spacing: 0.12em;
		text-transform: uppercase;
		color: #121212;
		opacity: 0.4;
		pointer-events: none;

		ul {
			display: flex;
			list-style-type: none;
			margin: 0;
			padding: 0;

			li {
				padding-right: vw(39);
			}
		}

		@media (max-width: $sm - 1) {
			display: none;
		}
	}

	@media (min-width: $sm) {
		// overflow: hidden;
		// height: 100vh;
	}
}
