// stylelint-disable scss/dollar-variable-empty-line-before

// COLORS

$color-black: #000;
$color-white: #fff;
$color-coffee-bean: #260d17;
$color-diesel: #140008;
$color-laser: #cfb569;
$color-cod-gray: #121212;
$color-heath: #4c0f28;
$color-alabaster: #f9f9f9;

// FONTS

$font-family-graphik: "Graphik", sans-serif;
$font-family-halvar: "Halvar", sans-serif;
$font-family-jetbrainsmono: "JetBrainsMono", sans-serif;

$sm: 1024px;
