.page-policy {
	padding: vm(114) vm(25) 0;
	color: #131313;

	&__title {
		margin: 0 0 vm(45);
		font-weight: 300;
		font-size: vm(26);
		line-height: vm(31);

		@media (min-width: $sm) {
			margin: 0 0 vw(70, 1920);
			font-size: vw(88, 1920);
			line-height: vw(123, 1920);
		}
	}

	&__subtitle {
		margin: vm(50) 0 vm(15);
		font-weight: 400;
		font-size: vm(18);
		line-height: vm(29);

		&:first-child {
			margin-top: 0;
		}

		@media (min-width: $sm) {
			margin: vw(76, 1920) 0 vw(21, 1920);
			font-size: vw(28, 1920);
			line-height: vw(45, 1920);
		}
	}

	&__text {
		font-weight: 300;
		font-size: vm(13);
		line-height: vm(21);
		letter-spacing: 0.02em;

		p {
			margin-top: vm(21);
			opacity: 0.9;

			&:first-child {
				margin-top: 0;
			}

			@media (min-width: $sm) {
				margin-top: vw(31, 1920);
			}
		}

		@media (min-width: $sm) {
			font-size: vw(18, 1920);
			line-height: vw(31, 1920);
		}
	}

	@media (min-width: $sm) {
		padding: vw(272, 1920) 0 0 vw(55, 1920);
		width: vw(1358, 1920);
	}
}
