.page-about {
	&__intro {
		position: relative;
		padding: vm(94) vm(25) vm(35);

		&__label {
			margin-bottom: vm(35);
			font-weight: 300;
			font-size: vm(15);
			line-height: vm(18);

			@media (min-width: $sm) {
				margin-bottom: vw(90);
				font-size: vw(33);
				line-height: vw(40);
			}
		}

		&__title {
			position: relative;
			z-index: 5;
			font-family: $font-family-halvar;
			font-weight: 300;
			font-size: vm(27);
			line-height: vm(32);
			letter-spacing: -0.04em;
			text-transform: uppercase;
			color: $color-heath;

			&__svg {
				position: absolute;
				left: vm(6);
				top: vm(30);
				z-index: -1;
				width: vm(162);
				height: vm(66);
				fill: none;
				stroke-dasharray: 1774;

				@media (min-width: $sm) {
					left: 50%;
					top: vf(110);
					width: vf(727);
					height: vf(288);
					transform: translateX(-50%);
				}
			}

			&__line {
				ul {
					list-style-type: none;
					margin: 0;
					padding: 0;

					li {
						display: none;

						+ li {
							@media (min-width: $sm) {
								margin-left: vf(73);
							}
						}

						&:last-child {
							display: block;

							@media (min-width: $sm) {
								display: inline-block;
								vertical-align: top;
							}
						}

						@media (min-width: $sm) {
							display: inline-block;
							vertical-align: top;
							font-size: vw(117);
							line-height: vw(140);
						}
					}

					@media (min-width: $sm) {
						font-size: 0;
						white-space: nowrap;
						transform: translateX(100%);
					}
				}
			}

			@media (min-width: $sm) {
				font-size: vw(117);
				line-height: vw(140);
			}
		}

		&__scroll {
			position: fixed;
			left: 50%;
			bottom: vf(173);
			display: none;
			transform: translateX(-50%);

			&__arrow {
				margin-bottom: vf(24);

				svg {
					display: inline-block;
					vertical-align: middle;
					width: vf(24);
					height: vf(52);
				}
			}

			&__text {
				font-family: $font-family-halvar;
				font-size: vf(10);
				line-height: vf(16);
				letter-spacing: 0.14em;
				text-transform: uppercase;
				opacity: 0.4;
			}

			@media (min-width: $sm) {
				display: block;
			}
		}

		@media (min-width: $sm) {
			padding: vw(155) 0 vw(163);
			text-align: center;
		}
	}

	&__benefit {
		padding: 0 vm(25);
		font-weight: 300;
		font-size: vm(15);
		line-height: vm(24);

		@media (min-width: $sm) {
			padding: 0 0 vw(100);
			font-size: vw(33);
			line-height: vw(40);
			text-align: center;
		}
	}

	&__image {
		padding: vm(35) 0 vm(29);

		&__fix {
			width: 100%;
		}

		&__width {
			img {
				width: 100%;
			}

			@media (min-width: $sm) {
				margin: 0 auto;
				width: vw(1091);
				height: vw(613);
			}
		}

		@media (min-width: $sm) {
			padding: 0;
		}
	}

	&__image-text {
		padding: 0 vm(25) vm(55);
		font-weight: 300;
		font-size: vm(14);
		line-height: vm(24);
		letter-spacing: 0.02em;

		&__row {
			@media (min-width: $sm) {
				display: flex;
				justify-content: space-between;
			}
		}

		&__col {
			flex-shrink: 0;

			&:nth-child(1) {
				@media (min-width: $sm) {
					width: vw(526);
				}
			}

			&:nth-child(2) {
				@media (min-width: $sm) {
					width: vw(362);
				}
			}
		}

		&__col + &__col {
			margin-top: vm(30);

			@media (min-width: $sm) {
				margin-top: 0;
			}
		}

		@media (min-width: $sm) {
			padding: vw(53) vw(324) vw(283) vw(307);
			font-weight: 300;
			font-size: vw(15);
			line-height: vw(26);
		}
	}

	&__benefit-item {
		padding: vm(26) vm(25);

		&__content {
			@media (min-width: $sm) {
				width: vw(610);
			}
		}

		&__title {
			margin: 0 0 vm(19);
			font-weight: 400;
			font-size: vm(22);
			line-height: vm(26);
			letter-spacing: -0.01em;

			@media (min-width: $sm) {
				margin: 0 0 vw(39);
				font-size: vw(50);
				line-height: vw(60);
			}
		}

		&__text {
			font-weight: 300;
			font-size: vm(14);
			line-height: vm(24);
			letter-spacing: 0.01em;
			opacity: 0.9;

			@media (min-width: $sm) {
				font-size: vw(24);
				line-height: vw(38);
			}
		}

		@media (min-width: $sm) {
			padding: 0 0 vw(250) vw(255);
		}
	}

	&__benefit-item--2 {
		@media (min-width: $sm) {
			padding-bottom: vw(66);
			padding-left: vw(599);
		}
	}

	&__image-2 {
		padding: vm(4) 0 vm(24);

		img {
			width: 100%;
		}

		@media (min-width: $sm) {
			padding: 0 vw(38) 0 vw(599);
		}
	}

	&__image-3 {
		img {
			width: 100%;
		}

		@media (min-width: $sm) {
			margin-top: vw(-400);
			width: vw(572);
		}
	}

	&__trust {
		padding: vm(30) vm(25);

		&__content {
			@media (min-width: $sm) {
				width: vw(609);
			}
		}

		&__title {
			margin: 0 0 vm(19);
			font-weight: 400;
			font-size: vm(22);
			line-height: vm(26);
			letter-spacing: -0.01em;

			@media (min-width: $sm) {
				margin: 0 0 vw(39);
				font-size: vw(50);
				line-height: vw(60);
			}
		}

		&__text {
			font-weight: 300;
			font-size: vm(14);
			line-height: vm(22);
			letter-spacing: 0.01em;
			opacity: 0.9;
			
			@media (min-width: $sm) {
				font-size: vw(24);
				line-height: vw(38);
			}
		}

		@media (min-width: $sm) {
			margin: vw(-600) 0 vw(400);
			padding: 0 0 0 vw(696);
		}
	}
}
