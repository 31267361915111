.form {
	&__title {
		margin-bottom: vm(25);
		font-weight: 300;
		font-size: vm(24);
		line-height: vm(34);

		@media (min-width: $sm) {
			margin-bottom: vf(40);
			font-size: vf(58);
			line-height: vf(81);
		}
	}

	&__fields-container {
		position: relative;
	}

	&__fields {
		position: relative;
		transition: 0.25s;
	}

	&__input {
		margin-bottom: vm(45);

		&__field {
			position: relative;

			input,
			textarea {
				display: block;
				outline: none;
				border: none;
				border-bottom: 1px solid rgba($color-white, 0.2);
				padding: 0;
				width: 100%;
				height: vm(45);
				font-size: vm(16);
				line-height: vm(19);
				letter-spacing: -0.01em;
				color: $color-white;
				background: none;

				&.error {
					border-color: #d92020;

					~ .form__input__error {
						display: block;
					}
				}

				&:focus ~ .form__input__label,
				&.is-filled ~ .form__input__label {
					top: 0;
					font-size: vm(8);
					line-height: vm(10);
					opacity: 1;

					@media (min-width: $sm) {
						font-size: vf(12);
						line-height: vf(14);
					}
				}

				@media (min-width: $sm) {
					height: vf(76);
					font-size: vf(22);
					line-height: vf(26);
				}
			}

			textarea {
				padding-top: vm(15);
				height: vm(150);
				resize: none;

				@media (min-width: $sm) {
					padding-top: vf(24.5);
					height: vf(205);
				}
			}

			label.error {
				display: none !important;
			}
		}

		&__label {
			position: absolute;
			left: 0;
			top: vm(15);
			font-size: vm(16);
			line-height: vm(19);
			letter-spacing: -0.01em;
			opacity: 0.4;
			transition: 0.25s;
			pointer-events: none;

			@media (min-width: $sm) {
				top: vf(24.5);
				font-size: vf(22);
				line-height: vf(26);
			}
		}

		&__error {
			position: absolute;
			right: 0;
			top: vm(15);
			display: none;
			width: vm(16);
			height: vm(16);

			@media (min-width: $sm) {
				top: vf(24);
				width: vf(30);
				height: vf(30);
			}
		}

		@media (min-width: $sm) {
			margin-bottom: vf(45);
			max-width: vf(460);
		}
	}

	&__input--full {
		@media (min-width: $sm) {
			max-width: none;
		}
	}

	&__file {
		margin-bottom: vm(44);

		&__button {
			font-size: vm(16);
			line-height: vm(19);
			letter-spacing: -0.01em;
			transition: 0.25s;
			cursor: pointer;

			input {
				display: none;
			}

			&:hover {
				@media (min-width: $sm) {
					border-bottom-color: rgba($color-white, 0.2);
				}
			}

			@media (min-width: $sm) {
				border-bottom: solid 2px transparent;
				font-size: vf(22);
				line-height: vf(26);
			}
		}

		&__list {
			display: flex;
			flex-wrap: wrap;
			margin-top: vm(17);

			@media (min-width: $sm) {
				margin-top: vf(17);
			}
		}

		&__item {
			position: relative;
			margin-right: vm(30);
			margin-bottom: vm(20);
			font-size: vm(15);
			line-height: vm(18);
			letter-spacing: -0.01em;
			word-break: break-all;
			color: rgba($color-white, 0.5);

			&__remove {
				position: relative;
				top: vm(-3);
				display: inline-block;
				vertical-align: middle;
				margin-left: vm(2);
				outline: none;
				border: none;
				padding: 0;
				width: vm(16);
				height: vm(16);
				background: none;
				opacity: 0.5;
				transition: 0.25s;

				&::before,
				&::after {
					content: "";
					position: absolute;
					left: 20%;
					top: 50%;
					width: 60%;
					height: 1px;
					background: $color-white;
				}

				&::before {
					transform: rotate(45deg);
				}

				&::after {
					transform: rotate(-45deg);
				}

				&:hover {
					opacity: 1;
				}

				@media (min-width: $sm) {
					top: vf(-3);
					margin-left: vf(2);
					width: vf(16);
					height: vf(16);
				}
			}

			@media (min-width: $sm) {
				margin-right: vf(30);
				margin-bottom: vf(20);
				font-size: vf(18);
				line-height: vf(22);
			}
		}

		@media (min-width: $sm) {
			margin-bottom: vf(105);
		}
	}

	&__submit {
		margin-top: vm(70);

		&__button {
			position: relative;
			overflow: visible;
			outline: none;
			border: none;
			border-radius: 50%;
			padding: 0;
			width: vm(133);
			height: vm(133);
			background: $color-laser;
			transition: 0.25s;

			&::before {
				content: "";
				position: absolute;
				left: 50%;
				top: 50%;
				border-right: solid 2px rgba(#4c0f28, 0.4);
				border-bottom: solid 2px rgba(#4c0f28, 0.4);
				width: vf(16);
				height: vf(32);
				opacity: 0;
				visibility: hidden;
				transform: translate(-50%, -50%) rotate(45deg);
				transition: 0.25s;
			}

			&__arrow {
				position: absolute;
				left: 50%;
				top: 50%;
				width: vm(25);
				height: vm(14);
				stroke: #0e0f11;
				stroke-width: 2px;
				transform: translate(-50%, -50%);
				transition: 0.25s;

				@media (min-width: $sm) {
					width: vf(38);
					height: vf(20);
				}
			}

			&__text {
				position: absolute;
				left: 50%;
				top: 100%;
				margin-top: vm(16);
				font-family: $font-family-halvar;
				font-size: vm(13);
				line-height: vm(21);
				letter-spacing: 0.14em;
				white-space: nowrap;
				text-align: center;
				text-transform: uppercase;
				color: $color-white;
				transform: translateX(-50%);

				&--success {
					display: none;
					opacity: 0.4;
				}

				@media (min-width: $sm) {
					margin-top: vf(26);
					font-size: vf(16);
					line-height: vf(26);
				}
			}

			@media (min-width: $sm) {
				width: vf(200);
				height: vf(200);

				&:hover {
					opacity: 0.7;
				}
			}
		}

		@media (min-width: $sm) {
			margin-top: vf(96);
		}
	}

	&__row {
		&__col {
			@media (min-width: $sm) {
				padding: 0 vf(28);
				width: 50%;
			}
		}

		&__col--full {
			@media (min-width: $sm) {
				width: 100%;
			}
		}

		@media (min-width: $sm) {
			display: flex;
			flex-wrap: wrap;
			margin: 0 vf(-28) vf(25);
		}
	}

	&__policy {
		margin-top: vm(119);
		font-size: vm(13);
		line-height: vm(16);
		color: rgba($color-white, 0.3);

		a {
			border-bottom: solid 1px transparent;
			text-decoration: none;
			color: $color-white;
			transition: 0.25s;

			&:hover {
				border-bottom-color: $color-white;
			}
		}

		@media (min-width: $sm) {
			margin-top: vf(174);
			font-size: vf(13);
			line-height: vf(16);
		}
	}

	&__success-message {
		position: absolute;
		left: 0;
		bottom: vf(42);
		width: 100%;
		font-weight: 300;
		font-size: vf(38);
		line-height: vf(53);
		opacity: 0;
		visibility: hidden;
		transform: translateY(vf(-50));
		transition: 0.25s;

		@media (min-width: $sm) {
			max-width: vf(560);
		}
	}

	&__hidden {
		display: none;
	}

	&__hidden-trigger {
		margin-bottom: vm(30);

		button {
			outline: none;
			border: none;
			padding: 0;
			font-size: vm(16);
			line-height: vm(21);
			letter-spacing: -0.01em;
			text-align: left;
			color: $color-white;
			background: none;
			transition: 0.25s;

			&:hover {
				@media (min-width: $sm) {
					border-bottom-color: rgba($color-white, 0.2);
				}
			}

			@media (min-width: $sm) {
				border-bottom: solid 2px transparent;
				font-size: vf(22);
				line-height: vf(26);
			}
		}

		@media (min-width: $sm) {
			margin-bottom: vf(50);
		}
	}

	&.is-form-sended {
		.form {
			&__fields {
				opacity: 0;
				visibility: hidden;
			}

			&__success-message {
				display: block;
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
			}

			&__submit {
				&__button {
					background: $color-white;
					opacity: 1;
					pointer-events: none;

					&::before {
						opacity: 1;
						visibility: visible;
					}

					&__arrow {
						left: 100%;
						opacity: 0;
						visibility: hidden;
					}

					&__text {
						&--send {
							display: none;
						}

						&--success {
							display: block;
						}
					}
				}
			}
		}
	}
}
