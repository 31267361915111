.page-handling {
	&__intro {
		position: relative;
		z-index: 2;
		display: flex;
		align-items: flex-end;
		padding: vm(32) vm(25);
		min-height: vm(533);

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			z-index: -1;
			width: 100%;
			height: 100%;
			background: url("../images/noise.gif") 50% 50%;
			opacity: 0.05;
		}

		&__background {
			position: absolute;
			left: 0;
			top: 0;
			z-index: -2;
			width: 100%;
			height: 100%;
			background: 50% 50% no-repeat;
			background-size: cover;

			&::before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 51%;
				background: linear-gradient(
					180deg,
					#01020c 0%,
					rgba(1, 2, 12, 0.991353) 6.67%,
					rgba(1, 2, 12, 0.96449) 13.33%,
					rgba(1, 2, 12, 0.91834) 20%,
					rgba(1, 2, 12, 0.852589) 26.67%,
					rgba(1, 2, 12, 0.768225) 33.33%,
					rgba(1, 2, 12, 0.668116) 40%,
					rgba(1, 2, 12, 0.557309) 46.67%,
					rgba(1, 2, 12, 0.442691) 53.33%,
					rgba(1, 2, 12, 0.331884) 60%,
					rgba(1, 2, 12, 0.231775) 66.67%,
					rgba(1, 2, 12, 0.147411) 73.33%,
					rgba(1, 2, 12, 0.0816599) 80%,
					rgba(1, 2, 12, 0.03551) 86.67%,
					rgba(1, 2, 12, 0.0086472) 93.33%,
					rgba(1, 2, 12, 0) 100%
				);
			}

			&::after {
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 49%;
				background: linear-gradient(
					0deg,
					#01020c 0%,
					rgba(1, 2, 12, 0.991353) 6.67%,
					rgba(1, 2, 12, 0.96449) 13.33%,
					rgba(1, 2, 12, 0.91834) 20%,
					rgba(1, 2, 12, 0.852589) 26.67%,
					rgba(1, 2, 12, 0.768225) 33.33%,
					rgba(1, 2, 12, 0.668116) 40%,
					rgba(1, 2, 12, 0.557309) 46.67%,
					rgba(1, 2, 12, 0.442691) 53.33%,
					rgba(1, 2, 12, 0.331884) 60%,
					rgba(1, 2, 12, 0.231775) 66.67%,
					rgba(1, 2, 12, 0.147411) 73.33%,
					rgba(1, 2, 12, 0.0816599) 80%,
					rgba(1, 2, 12, 0.03551) 86.67%,
					rgba(1, 2, 12, 0.0086472) 93.33%,
					rgba(1, 2, 12, 0) 100%
				);
			}
		}

		&__content {
			@media (min-width: $sm) {
				max-width: vf(1329);
			}
		}

		&__text {
			font-size: vm(32);
			line-height: 1.2;
			letter-spacing: -0.02em;

			@media (min-width: $sm) {
				font-size: vf(72);
				line-height: 1.3;
			}
		}

		@media (min-width: $sm) {
			padding: vf(197) vf(60);
			min-height: 100vh;
		}
	}

	&__video {
		margin-bottom: vm(90);
		padding: vm(37) vm(25) 0;

		&__image {
			position: relative;
			margin: 0 auto vm(24);
			width: vm(270);
			height: vm(270);

			img {
				border-radius: 50%;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			@media (min-width: $sm) {
				margin: 0 auto vf(62);
				width: vf(829);
				height: vf(829);
			}
		}

		&__icon {
			position: absolute;
			right: 0;
			bottom: 0;
			border-radius: 50%;
			width: vm(90);
			height: vm(90);
			background: #4c0f28;

			&::before {
				content: "";
				position: absolute;
				left: 50%;
				top: 50%;
				border-top: solid vm(5) transparent;
				border-bottom: solid vm(5) transparent;
				border-left: solid vm(8) $color-white;
				transform: translate(-50%, -50%);

				@media (min-width: $sm) {
					border-top-width: vw(8);
					border-bottom-width: vw(8);
					border-left-width: vw(11);
				}
			}

			@media (min-width: $sm) {
				right: vf(-21);
				bottom: vf(32);
				width: vf(196);
				height: vf(196);
			}
		}

		&__caption {
			text-align: center;
		}

		&__title {
			margin: 0 0 vm(15);
			font-weight: 400;
			font-size: vm(28);
			line-height: 1.2;
			letter-spacing: -0.01em;

			span {
				position: relative;

				svg {
					position: absolute;
					left: 50%;
					top: 50%;
					z-index: -1;
					width: vm(123);
					height: vm(48);
					stroke-dasharray: 1143;
					stroke-dashoffset: 0;
					transform: translate(-45%, -52%);
				}
			}

			@media (min-width: $sm) {
				margin: 0 0 vf(39);
				font-size: vf(114);
				letter-spacing: -0.03em;

				span {
					position: relative;

					svg {
						width: vf(479);
						height: vf(182);
						transform: translate(-42%, -58%);
					}
				}
			}
		}

		&__label {
			margin-bottom: vm(8);
			font-family: $font-family-halvar;
			font-weight: 500;
			font-size: vm(8);
			line-height: 1.6;
			letter-spacing: 0.2em;
			text-transform: uppercase;
			opacity: 0.5;

			@media (min-width: $sm) {
				margin-bottom: vf(31);
				font-size: vf(12);
			}
		}

		&__person {
			margin: 0 auto;
			width: vm(202);
			font-size: vm(12);
			line-height: 1.6;

			&__text {
				opacity: 0.6;
			}

			@media (min-width: $sm) {
				width: vf(590);
				font-size: vf(24);
			}
		}

		@media (min-width: $sm) {
			margin-bottom: vf(169);
			padding: vf(58) vf(222) 0;
		}
	}

	&__when {
		margin-bottom: vm(80);
		border-top: solid 1px #20212a;

		&__content {
			border-right: solid 1px #20212a;
			padding: vm(9) vm(50) vm(15) vm(14);

			@media (min-width: $sm) {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				border-right: none;
				padding: vf(23) vf(150) vf(41) vf(39);
				width: 50%;
			}
		}

		&__title {
			margin: 0 0 vm(62);
			font-family: $font-family-halvar;
			font-weight: 700;
			font-size: vm(8);
			line-height: 2.1;
			letter-spacing: 0.07em;
			text-transform: uppercase;

			@media (min-width: $sm) {
				margin: 0;
				font-size: vf(18);
			}
		}

		&__text {
			font-weight: 300;
			font-size: vm(22);
			line-height: 1.3;
			letter-spacing: -0.01em;

			span {
				border-radius: vf(28);
				padding: vf(8) vf(20);
				font-weight: 400;
				letter-spacing: -0.04em;
				color: #01020c;
				background: $color-white;
			}

			@media (min-width: $sm) {
				font-size: vf(68);
			}
		}

		&__list {
			display: flex;
			flex-shrink: 0;
			flex-wrap: wrap;

			@media (min-width: $sm) {
				border-left: solid 1px #20212a;
				width: 50%;
			}
		}

		&__item {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			margin: -1px 0 0 -1px;
			border-top: solid 1px #20212a;
			border-left: solid 1px #20212a;
			padding: vm(7) vm(15) vm(14) vm(14);
			width: calc(50% + 1px);
			min-height: vm(150);

			&__number {
				font-family: $font-family-halvar;
				font-weight: 700;
				font-size: vm(10);
				line-height: 2.1;
				letter-spacing: 0.08em;
				text-transform: uppercase;
				opacity: 0.2;

				@media (min-width: $sm) {
					font-size: vf(14);
				}
			}

			&__text {
				max-width: vm(131);
				font-weight: 300;
				font-size: vm(15);
				line-height: 1.3;

				@media (min-width: $sm) {
					max-width: vf(363);
					font-size: vf(36);
				}
			}

			&:last-child {
				margin-bottom: -1px;
				border: none;
				width: calc(100% + 1px);
				background: #4c0f28;

				.page-handling__when__item__text {
					max-width: vm(186);

					@media (min-width: $sm) {
						max-width: vf(393);
					}
				}
			}

			@media (min-width: $sm) {
				padding: vf(19) vf(30) vf(27) vf(30);
				min-height: vf(374);
			}
		}

		@media (min-width: $sm) {
			display: flex;
			margin-bottom: vf(284);
			border-bottom: solid 1px #20212a;
			padding-right: vf(46);
		}
	}

	&__services {
		margin-bottom: vm(21);

		&::after {
			content: "";
			display: block;
			margin-top: vm(65);
			height: vm(51);
			background: url("../images/handling-services-item-head.svg") 100% 0 no-repeat;
			background-size: auto 100%;

			@media (min-width: $sm) {
				margin-top: vf(94);
				height: vf(122);
			}
		}

		&__title {
			margin: 0 auto vm(49);
			padding: 0 vm(25);
			font-weight: 400;
			font-size: vm(28);
			line-height: 1.2;
			letter-spacing: -0.03em;
			text-align: center;

			&__plus {
				position: relative;
				display: inline-block;
				vertical-align: middle;
				margin: vm(-5) vm(-15) 0 vm(2);
				border-radius: 50%;
				width: vm(24);
				height: vm(24);
				background: #4c0f28;

				&::before {
					content: "";
					position: absolute;
					left: 50%;
					top: 50%;
					width: vm(8);
					height: vm(2);
					background: $color-white;
					transform: translate(-50%, -50%);
				}

				&::after {
					content: "";
					position: absolute;
					left: 50%;
					top: 50%;
					width: vm(2);
					height: vm(8);
					background: $color-white;
					transform: translate(-50%, -50%);
				}

				@media (min-width: $sm) {
					margin: vf(-22) 0 0 vf(16);
					width: vf(100);
					height: vf(100);

					&::before {
						width: vf(28);
						height: vf(6);
					}

					&::after {
						width: vf(6);
						height: vf(28);
					}
				}
			}

			&__line {
				position: relative;

				svg {
					position: absolute;
					left: 50%;
					top: 50%;
					z-index: -1;
					width: vm(132);
					height: vm(52);
					stroke-dasharray: 1143;
					stroke-dashoffset: 0;
					transform: translate(-46%, -47%);
				}

				@media (min-width: $sm) {
					svg {
						width: vf(531);
						height: vf(202);
						transform: translate(-49%, -48%);
					}
				}
			}

			@media (min-width: $sm) {
				margin: 0 auto vf(79);
				padding: 0;
				max-width: vf(1400);
				font-size: vf(114);
			}
		}

		&__item {
			&__head {
				margin-bottom: vm(27);
				padding-top: vm(17);
				height: vm(51);
				background: url("../images/handling-services-item-head.svg") 100% 0 no-repeat;
				background-size: auto 100%;

				&__text {
					display: flex;
					margin-left: auto;
					width: vm(180);
					font-family: $font-family-halvar;
					font-weight: 700;
					font-size: vm(10);
					line-height: 1;
					letter-spacing: 0.07em;
					text-transform: uppercase;
					color: rgba($color-white, 0.1);

					span {
						min-width: vm(35);
						color: $color-white;
					}

					@media (min-width: $sm) {
						width: vf(435);
						font-size: vf(25);

						span {
							min-width: vf(84);
						}
					}
				}

				@media (min-width: $sm) {
					margin-bottom: vf(64);
					padding-top: vf(37);
					height: vf(122);
				}
			}

			&__text {
				padding: 0 vm(30) 0 vm(25);
				font-weight: 300;
				font-size: vm(22);
				line-height: 1.4;
				letter-spacing: -0.01em;

				&::before {
					content: "";
					display: inline-block;
					vertical-align: middle;
					margin: vm(-8) vm(14) 0 0;
					border: vm(1) solid $color-white;
					border-radius: 50%;
					width: vm(10);
					height: vm(10);
				}

				@media (min-width: $sm) {
					padding: 0 0 0 vf(70);
					min-height: vf(252);
					max-width: vf(1553);
					font-size: vf(60);

					&::before {
						margin: vf(-25) vf(35) 0 0;
						border: vf(3) solid $color-white;
						width: vf(26);
						height: vf(26);
					}
				}
			}

			&__line {
				position: relative;
				display: inline-block;

				svg {
					position: absolute;
					left: 50%;
					top: 100%;
					z-index: -1;
					stroke-dashoffset: 0;
					transform: translate(-50%, -50%);
				}

				&--1 {
					svg {
						width: vm(223);
						height: vm(8);
						stroke-dasharray: 1481.5;
						transform: translate(-51%, -60%);
					}
				}

				&--2 {
					svg {
						width: vm(232);
						height: vm(9);
						stroke-dasharray: 947.2;
						transform: translate(-51%, -45%);
					}
				}

				&--3 {
					svg {
						width: vm(150);
						height: vm(12);
						stroke-dasharray: 838.2;
						transform: translate(-51%, -55%);
					}
				}

				&--4 {
					svg {
						width: vm(155);
						height: vm(15);
						stroke-dasharray: 1133.5;
						transform: translate(-51%, -40%);
					}
				}

				@media (min-width: $sm) {
					&--1 {
						svg {
							width: vf(594);
							height: vf(19);
							transform: translate(-51%, -45%);
						}
					}

					&--2 {
						svg {
							width: vf(612);
							height: vf(21);
							transform: translate(-51%, -40%);
						}
					}

					&--3 {
						svg {
							width: vf(407);
							height: vf(31);
							transform: translate(-51%, -50%);
						}
					}

					&--4 {
						svg {
							width: vf(424);
							height: vf(37);
							transform: translate(-51%, -40%);
						}
					}
				}
			}

			& + & {
				margin-top: vm(65);

				@media (min-width: $sm) {
					margin-top: vf(94);
				}
			}
		}

		@media (min-width: $sm) {
			margin-bottom: vf(126);
			padding: 0 vf(46) 0 0;
		}
	}

	&__consulting {
		padding: 0 vm(25);
		text-align: center;

		&__title {
			margin: 0 0 vm(33);
			font-weight: 400;
			font-size: vm(28);
			line-height: 1.2;
			letter-spacing: -0.03em;

			&__check {
				display: inline-block;
				vertical-align: middle;
				margin: vm(-10) vm(5) 0 0;
				width: vm(26);
				height: vm(26);

				@media (min-width: $sm) {
					margin: vf(-30) vf(12) 0 0;
					width: vf(90);
					height: vf(90);
				}
			}

			&__animate {
				position: relative;
				display: inline-block;
			}

			&__svg {
				position: absolute;
				left: 50%;
				top: 50%;
				width: vm(240);
				height: vm(18);
				stroke-dasharray: 2729;
				stroke-dashoffset: 0;
				transform: translate(-58%, 57%) rotate(-8deg);

				@media (min-width: $sm) {
					width: vf(1324);
					height: vf(90);
					transform: translate(-25%, -50%);
				}
			}

			&__svg-2 {
				position: relative;

				svg {
					position: absolute;
					left: 50%;
					top: 50%;
					z-index: -1;
					width: vm(216);
					height: vm(43);
					stroke-dasharray: 1810;
					stroke-dashoffset: 0;
					transform: translate(-48%, -48%);
				}

				@media (min-width: $sm) {
					svg {
						width: vf(855);
						height: vf(167);
					}
				}
			}

			@media (min-width: $sm) {
				margin: 0 0 vf(155);
				font-size: vf(114);
			}
		}

		&__text {
			margin: 0 auto;
			font-weight: 300;
			font-size: vm(14);
			line-height: 1.6;
			letter-spacing: 0.01em;
			opacity: 0.9;

			@media (min-width: $sm) {
				width: vf(865);
				font-size: vf(29);
			}
		}

		@media (min-width: $sm) {
			padding: 0 vf(212);
		}
	}
}
