.back {
	position: fixed;
	left: 50%;
	top: vm(24);
	z-index: 20;
	overflow: visible;
	outline: none;
	border: none;
	border-radius: 50%;
	padding: 0;
	width: vm(55);
	height: vm(55);
	background: $color-laser;
	transform: translateX(-50%);

	&__arrow {
		position: absolute;
		left: 50%;
		top: 50%;
		width: vm(16);
		height: vm(9);
		stroke: #0e0f11;
		stroke-width: 2px;
		transform: translate(-50%, -50%) rotate(180deg);
		transition: 0.25s;

		.is-page-light & {
			stroke: $color-white;
		}

		@media (min-width: $sm) {
			width: vf(28);
			height: vf(15);
		}
	}

	&__text {
		position: absolute;
		left: 50%;
		top: 100%;
		display: none;
		margin-top: vm(16);
		font-family: $font-family-halvar;
		font-size: vm(13);
		line-height: vm(21);
		letter-spacing: 0.14em;
		white-space: nowrap;
		text-align: center;
		text-transform: uppercase;
		color: rgba($color-white, 0.4);
		transform: translateX(-50%);
		pointer-events: none;

		.is-page-light & {
			color: rgba($color-cod-gray, 0.4);
		}

		@media (min-width: $sm) {
			display: block;
			margin-top: vf(19);
			font-size: vf(13);
			line-height: vf(21);
		}
	}

	.is-page-light & {
		background: $color-heath;
	}

	@media (min-width: $sm) {
		top: vf(46);
		width: vf(150);
		height: vf(150);

		&:hover {
			opacity: 0.7 !important;
			transition: 0.25s;
		}
	}
}
