.menu {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 50;
	overflow: auto;
	padding: vm(23) vm(20) vm(35);
	width: 100%;
	height: 100%;
	color: $color-white;
	background: $color-diesel;
	// background: $color-diesel url("../images/noise.png") 50% 50%;
	opacity: 0;
	visibility: hidden;
	transition: 0.5s;

	&::before {
		content: "";
		position: fixed;
		left: 0;
		top: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		background: url("../images/noise.gif") 50% 50%;
		opacity: 0.05;
	}

	&__logo {
		margin-bottom: vm(52);

		img {
			display: block;
			width: vw(140);
		}

		svg {
			width: vw(140);
			height: vw(49);
			fill: currentColor;
		}

		&__top {
			svg {
				display: inline-block;
				vertical-align: middle;
				width: vm(52);
				height: vm(22);

				@media (min-width: $sm) {
					width: vw(65);
					height: vw(27);
				}
			}

			@media (min-width: $sm) {
				margin-bottom: vw(1);
			}
		}

		&__bottom {
			svg {
				display: inline-block;
				vertical-align: middle;
				width: vw(118);
				height: vw(7);
			}

			@media (max-width: $sm - 1) {
				display: none;
			}
		}

		@media (min-width: $sm) {
			margin-bottom: vw(98, 1920);
			width: vw(118);
			text-align: center;
		}
	}

	&__type {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: vm(46);
		opacity: 0;
		transform: translateY(vm(50));
		transition: 0.25s 0.5s;

		button {
			position: relative;
			z-index: 5;
			display: block;
			overflow: visible;
			outline: none;
			border: none;
			padding: 0;
			font-family: $font-family-halvar;
			font-weight: 300;
			font-size: vm(26);
			line-height: vm(31);
			letter-spacing: -0.02em;
			text-transform: uppercase;
			color: $color-white;
			background: none;
			opacity: 0.2;
			transition: 0.25s;

			+ button {
				margin-top: vm(14);

				@media (min-width: $sm) {
					margin: 0 0 0 vw(55, 1920);
				}
			}

			svg {
				position: absolute;
				left: vm(12);
				top: 50%;
				z-index: -1;
				width: vm(125);
				height: vm(92);
				fill: none;
				stroke-dasharray: 865;
				stroke-dashoffset: 864;
				transform: translateY(-50%);
				transition: 0.5s;
				pointer-events: none;

				@media (min-width: $sm) {
					left: vw(12, 1920);
					width: vw(403, 1920);
					height: vw(292, 1920);
					transform: translateY(-58%);
				}
			}

			&:hover {
				opacity: 1;
			}

			&.is-active {
				opacity: 1;

				svg {
					.is-show-menu & {
						stroke-dashoffset: 0;
						transition: 1s 0.75s;
					}
				}
			}

			@media (min-width: $sm) {
				font-size: vw(88, 1920);
				line-height: vw(106, 1920);
			}
		}

		.is-show-menu & {
			opacity: 1;
			transform: translateY(0);
		}

		@media (min-width: $sm) {
			flex-direction: row;
			margin-bottom: vw(63, 1920);
			transform: translateY(vw(50));
		}
	}

	&__row {
		display: none;
		opacity: 0;
		transform: translateY(vm(50));
		transition: 0 0.5s;

		a {
			text-decoration: none;
			color: $color-white;
			transition: 0.25s;

			.is-link-hover & {
				@media (min-width: $sm) {
					opacity: 0.2;

					&:hover {
						opacity: 1;
					}
				}
			}
		}

		&.is-active {
			display: block;
			animation: showMenuRow 0.5s;

			@media (min-width: $sm) {
				display: flex;
			}
		}

		.is-show-menu & {
			opacity: 0.95;
			transform: translateY(0);
			transition: 0.25s 0.5s;
		}

		@media (min-width: $sm) {
			transform: translateY(vw(50));
		}
	}

	&__global {
		margin-bottom: vm(70);
		font-size: vm(22);
		line-height: vm(26);
		letter-spacing: -0.01em;

		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;

			li {
				+ li {
					margin-top: vm(26);

					@media (min-width: $sm) {
						margin-top: vw(18, 1920);
					}
				}

				svg {
					display: inline-block;
					vertical-align: middle;
					margin-top: vm(-6);
					margin-left: vm(8);
					width: vm(14);
					height: vm(14);
					fill: none;

					@media (min-width: $sm) {
						margin-top: vw(-8, 1920);
						margin-left: vw(12, 1920);
						width: vw(20, 1920);
						height: vw(20, 1920);
					}
				}
			}
		}

		@media (min-width: $sm) {
			margin: 0 vw(136, 1920) 0 0;
			font-size: vw(34, 1920);
			line-height: vw(41, 1920);
		}
	}

	&__other {
		&__row {
			@media (min-width: $sm) {
				display: flex;
			}
		}

		&__label {
			margin-bottom: vm(24);
			font-size: vm(10);
			line-height: vm(16);
			letter-spacing: 0.14em;
			text-transform: uppercase;
			opacity: 0.65;

			@media (min-width: $sm) {
				margin-bottom: vw(24, 1920);
				font-size: vw(10, 1920);
				line-height: vw(16, 1920);
			}
		}

		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
			font-size: vm(16);
			line-height: vm(22);
			letter-spacing: -0.01em;

			li {
				+ li {
					margin-top: vm(18);

					@media (min-width: $sm) {
						margin-top: vw(17, 1920);
					}
				}
			}

			@media (min-width: $sm) {
				font-size: vw(22, 1920);
				line-height: vw(26, 1920);
			}
		}

		&__row + &__row {
			margin-top: vm(56);

			@media (min-width: $sm) {
				margin-top: vw(80, 1920);
			}
		}

		&__col + &__col {
			margin-top: vm(56);

			@media (min-width: $sm) {
				margin: 0 0 0 vw(80, 1920);
			}
		}
	}

	&__footer {
		display: flex;
		flex-direction: column;
		margin-top: vm(78);
		font-family: $font-family-halvar;
		font-weight: 300;
		font-size: vm(20);
		line-height: vm(23);
		letter-spacing: 0.03em;
		text-transform: uppercase;
		opacity: 0;
		transition: 0 0.5s;

		&__lang {
			display: flex;
			list-style-type: none;
			margin: 0;
			margin-top: vm(62);
			padding: 0;

			li {
				+ li {
					margin-left: vm(21);

					@media (min-width: $sm) {
						margin-left: vw(27, 1920);
					}
				}

				a {
					text-decoration: none;
					color: inherit;
					transition: 0.25s;

					&:hover {
						opacity: 0.6;
					}

					&.is-active {
						opacity: 0.4;
					}
				}
			}

			@media (min-width: $sm) {
				margin-top: 0;
			}
		}

		&__policy {
			margin: vm(61) 0 0;
			font-family: $font-family-graphik;
			font-size: vm(14);
			letter-spacing: -0.01em;
			text-transform: none;

			a {
				text-decoration: none;
				color: inherit;
				opacity: 0.4;
				transition: 0.25s;

				&:hover {
					opacity: 1;
				}
			}

			@media (min-width: $sm) {
				margin: 0 auto 0 vf(180);
				font-size: vf(18);
			}

			@media (max-width: $sm - 1) {
				order: 2;
			}
		}

		&__menu {
			ul {
				list-style-type: none;
				margin: 0;
				padding: 0;

				li {
					+ li {
						margin-top: vm(30);

						@media (min-width: $sm) {
							margin: 0 0 0 vw(105, 1920);
						}
					}

					a {
						position: relative;
						z-index: 5;
						text-decoration: none;
						color: inherit;
						transition: 0.25s;

						svg {
							position: absolute;
							left: 50%;
							top: 50%;
							z-index: -1;
							width: vw(108, 1920);
							height: vw(79, 1920);
							fill: none;
							stroke-dasharray: 226;
							stroke-dashoffset: 226;
							transform: translate(-50%, -50%);
							transition: 0.5s;
							pointer-events: none;

							@media (max-width: $sm - 1) {
								display: none;
							}
						}

						&:hover {
							svg {
								stroke-dashoffset: 0;
							}
						}
					}
				}

				@media (min-width: $sm) {
					display: flex;
				}
			}
		}

		.is-show-menu & {
			opacity: 0.95;
			transform: translateY(0);
			transition: 0.25s 0.5s;
		}

		@media (min-width: $sm) {
			position: absolute;
			left: vw(50);
			bottom: vw(68, 1920);
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			margin-top: 0;
			width: vw(1440, 1920);
			font-size: vw(20, 1920);
			line-height: vw(23, 1920);
		}
	}

	.is-show-menu & {
		opacity: 1;
		visibility: visible;
	}

	.is-page-digital & {
		background: #000313;
	}

	@media (min-width: $sm) {
		padding: vw(40) vw(97) 0 vw(50);
	}
}

@keyframes showMenuRow {
	0% {
		opacity: 0;
		transform: translateY(50px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
