.page-digital {
	padding: 0 vm(25);

	.back {
		background: $color-white;
	}

	&__intro {
		position: relative;
		z-index: 5;
		padding: vm(121) 0 vm(55);
		font-family: $font-family-jetbrainsmono;
		font-weight: 500;
		text-align: center;

		&::before {
			content: "";
			position: absolute;
			left: vm(-175);
			top: vm(-85);
			z-index: -1;
			width: vm(792);
			height: vm(672);
			background: url("../images/digital-intro-bg.png") 50% 50% no-repeat;
			background-size: contain;

			@media (min-width: $sm) {
				left: vf(6);
				top: vf(-15);
				width: vf(1346);
				height: vf(1143);
			}
		}

		&__title {
			font-size: vm(40);
			line-height: vm(48);
			letter-spacing: -0.07em;
			text-transform: uppercase;

			@media (min-width: $sm) {
				font-size: vf(170);
				line-height: vf(204);
			}
		}

		&__text {
			margin: vm(47) auto 0;
			font-size: vm(15);
			line-height: vm(24);

			@media (min-width: $sm) {
				margin: vf(57) auto 0;
				max-width: vf(740);
				font-size: vf(30);
				line-height: vf(48);
			}
		}

		@media (min-width: $sm) {
			padding: vf(333) vf(347);
		}
	}

	&__slogan {
		padding: vm(55) 0 vm(67);
		font-family: $font-family-jetbrainsmono;
		font-size: vm(18);
		line-height: vm(29);

		@media (min-width: $sm) {
			padding: vf(236) vf(405);
			font-size: vf(44);
			line-height: vf(70);
		}
	}

	&__video {
		position: relative;
		z-index: 5;
		padding: vm(67) 0 vm(72);

		&::before {
			content: "";
			position: absolute;
			left: vm(-125);
			top: vm(-125);
			z-index: -1;
			width: vm(637);
			height: vm(655);
			background: url("../images/digital-video-bg.png") 50% 50% no-repeat;
			background-size: contain;

			@media (min-width: $sm) {
				left: 0;
				top: vf(262);
				width: vf(1338);
				height: vf(1376);
			}
		}

		&__text {
			position: relative;
			z-index: 10;
			font-weight: 300;
			font-size: vm(32);
			line-height: vm(42);
			letter-spacing: -0.01em;
			pointer-events: none;

			@media (min-width: $sm) {
				margin-left: vf(169);
				max-width: vf(885);
				font-size: vf(90);
				line-height: vf(117);
			}
		}

		.page-index__video {
			margin: vm(-62) vm(-25) 0;
			padding: 0;

			&__image {
				position: relative;
				left: auto;
				top: auto;
				margin: 0;
				width: 100%;

				img {
					@media (max-width: $sm - 1) {
						height: vm(320);
						object-fit: cover;
						object-position: 70% 50%;
					}
				}
			}

			&__button {
				background: $color-white;

				&::before {
					border-left-color: #5514a7;
				}

				@media (max-width: $sm - 1) {
					left: auto;
					right: vm(32);
					top: auto;
					bottom: vm(32);
					margin: 0;
				}
			}

			@media (min-width: $sm) {
				margin: vf(-170) 0 0;
			}
		}

		&__info {
			margin: vm(23) 0 0 auto;
			max-width: vm(200);
			font-size: vm(14);
			line-height: vm(22);
			text-align: right;

			&__text {
				color: #9c9da3;
			}

			@media (min-width: $sm) {
				margin: vf(44) 0 0;
				max-width: none;
				font-size: vf(24);
				line-height: vf(38);
			}
		}

		@media (min-width: $sm) {
			padding: vf(372) vf(236);
		}
	}

	&__items {
		position: relative;
		z-index: 5;
		padding: vm(72) 0 vm(65);

		&::before {
			content: "";
			position: absolute;
			left: vm(-225);
			top: vm(50);
			z-index: -1;
			width: vm(603);
			height: vm(812);
			background: url("../images/digital-items-bg-1.png") 50% 50% no-repeat;
			background-size: contain;

			@media (min-width: $sm) {
				left: 0;
				top: vf(250);
				width: vf(1026);
				height: vf(1380);
			}
		}

		&::after {
			content: "";
			position: absolute;
			left: vm(-400);
			bottom: vm(450);
			z-index: -1;
			width: vm(1004);
			height: vm(882);
			background: url("../images/digital-items-bg.png") 50% 50% no-repeat;
			background-size: contain;
			transform: rotate(-45deg);

			@media (min-width: $sm) {
				left: vf(50);
				bottom: vf(250);
				width: vf(1718);
				height: vf(1880);
				transform: none;
			}
		}

		&__title {
			margin: 0 0 vm(36);
			font-weight: 300;
			font-size: vm(32);
			line-height: vm(38);
			letter-spacing: -0.02em;

			@media (min-width: $sm) {
				margin: 0 0 vf(135);
				max-width: vf(630);
				font-size: vf(90);
				line-height: vf(108);
			}
		}

		&__row {
			@media (min-width: $sm) {
				display: flex;
				flex-wrap: wrap;
			}
		}

		&__item {
			&__title {
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px solid rgba($color-white, 0.15);
				border-radius: 50%;
				padding: vm(18);
				width: vm(208);
				height: vm(208);
				font-family: $font-family-jetbrainsmono;
				font-weight: 500;
				font-size: vm(15.6);
				line-height: vm(25);
				text-align: center;

				@media (min-width: $sm) {
					padding: vf(48);
					width: vf(393);
					height: vf(393);
					font-size: vf(28);
					line-height: vf(45);
				}
			}

			&__text {
				margin-top: vm(15);
				font-size: vm(13);
				line-height: vm(23);
				letter-spacing: 0.02em;
				opacity: 0.85;

				@media (min-width: $sm) {
					margin-top: vf(43);
					font-size: vf(22);
					line-height: vf(40);
				}
			}

			@media (min-width: $sm) {
				&--1 {
					width: vf(512);
				}
	
				&--2 {
					margin-top: vf(268);
					margin-left: vf(163);
					width: vf(512);
				}
	
				&--3 {
					margin-top: vf(-63);
					width: vf(560);
				}
	
				&--4 {
					margin-top: vf(182);
					margin-left: vf(220);
					width: vf(512);
				}
	
				&--5 {
					margin-top: vf(-42);
					margin-left: vf(90);
					width: vf(1006);
				}
	
				&--6 {
					margin-top: vf(223);
					margin-left: vf(-107);
					width: vf(589);
				}
	
				&--7 {
					margin-top: vf(136);
					margin-left: auto;
					width: vf(646);
				}
	
				&--8 {
					margin-top: vf(106);
					margin-left: vf(265);
					width: vf(589);
					text-align: center;
	
					.page-digital__items__item__title {
						margin-right: auto;
						margin-left: auto;
					}
				}
			}
		}

		&__item + &__item {
			@media (max-width: $sm - 1) {
				margin-top: vm(40);
			}
		}

		@media (min-width: $sm) {
			padding: vf(154) vf(161) vf(154) vf(401);
		}
	}

	&__we {
		position: relative;
		z-index: 5;
		padding-top: vm(65);

		&__bg {
			position: absolute;
			left: 0;
			top: -30vh;
			z-index: -1;
			width: 100%;
			height: 100vh;

			&::before {
				content: "";
				position: absolute;
				left: vm(-700);
				top: vm(0);
				width: vm(1507);
				height: vm(1324);
				background: url("../images/digital-items-bg.png") 50% 50% no-repeat;
				background-size: contain;
				transform: rotate(90deg);

				@media (min-width: $sm) {
					left: 50%;
					top: 50%;
					width: 100vmax;
					height: 100vmax;
					background-size: vf(1718) auto;
					transform: translate(-50%, -50%) rotate(0);
				}
			}

			@media (min-width: $sm) {
			}
		}

		&__word {
			font-family: $font-family-jetbrainsmono;
			font-weight: 500;
			font-size: vm(42);
			line-height: vm(50);
			letter-spacing: -0.04em;
			text-transform: uppercase;

			@media (min-width: $sm) {
				flex-shrink: 0;
				margin: 0 vf(59) 0 0;
				width: vf(198);
				font-size: vf(170);
				line-height: vf(190);
				opacity: 0.2;
			}
		}

		&__list {
			@media (min-width: $sm) {
				padding-top: vf(670);
				width: 100%;
			}
		}

		&__item {
			&__title {
				font-family: $font-family-jetbrainsmono;
				font-weight: 500;
				font-size: vm(42);
				line-height: vm(50);
				letter-spacing: -0.04em;
				text-transform: uppercase;

				@media (min-width: $sm) {
					font-size: vf(170);
					line-height: vf(190);
				}
			}

			&__text {
				margin-top: vm(16);
				font-size: vm(14);
				line-height: vm(22);
				letter-spacing: 0.02em;
				opacity: 0.85;

				@media (min-width: $sm) {
					margin-top: vf(40);
					max-width: vf(915);
					font-size: vf(22);
					line-height: vf(40);
				}
			}

			@media (min-width: $sm) {
				max-width: vf(1000);
			}
		}

		&__item + &__item {
			margin-top: vm(57);

			@media (min-width: $sm) {
				margin-top: vf(145);
			}
		}

		@media (min-width: $sm) {
			display: flex;
			margin-top: vf(290);
			padding: 0 0 0 vf(190);
		}
	}

	@media (min-width: $sm) {
		padding: 0;
	}
}
