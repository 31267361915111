.menu-button {
	position: fixed;
	right: vm(20);
	top: vm(20);
	z-index: 55;
	outline: none;
	border: 0;
	padding: 0;
	width: vm(44);
	height: vm(28);
	background: none;

	&__icon {
		position: absolute;
		left: 50%;
		top: 50%;
		width: vm(40);
		height: vm(44);
		transform: translate(-50%, -50%) rotate(90deg);

		i {
			position: absolute;
			left: 50%;
			top: 0;
			margin-left: vm(-5);
			width: 1px;
			height: 100%;
			background: $color-white;
			transition: 0.25s;

			+ i {
				margin-left: vm(4);

				@media (min-width: $sm) {
					left: vw(8.32);
					margin: 0;
				}
			}

			.is-page-light & {
				@media (max-width: $sm - 1) {
					background: #131313;
				}
			}

			@media (min-width: $sm) {
				left: 0;
				margin: 0;
				width: vw(1.68);
			}
		}

		@media (min-width: $sm) {
			width: vw(10);
			height: vw(45);
			transform: translate(-50%, -50%);
		}
	}

	&__text {
		position: absolute;
		left: vw(-20);
		top: 50%;
		font-family: $font-family-halvar;
		font-size: vw(11);
		line-height: vw(18);
		letter-spacing: 0.14em;
		text-align: center;
		text-transform: uppercase;
		color: #757575;
		opacity: 0.4;
		transform: rotate(-90deg) translate(-50%, -50%);
		transform-origin: 0% 0%;
		transition: 0.25s;

		&--close {
			opacity: 0;
			visibility: hidden;
		}

		@media (max-width: $sm - 1) {
			display: none;
		}
	}

	&:hover {
		@media (min-width: $sm) {
			width: vw(56, 1920);
			background: #321621;

			.is-page-digital & {
				background: lighten(#111422, 5%);
			}
		}
	}

	.is-state-preloader & {
		opacity: 0;
	}

	.is-show-menu & {
		&__icon {
			i {
				left: 50%;
				margin: 0;
				transform: rotate(45deg);

				+ i {
					transform: rotate(-45deg);
				}

				@media (min-width: $sm) {
					left: vw(4);
				}
			}

			@media (min-width: $sm) {
				position: absolute;
				left: 50%;
				top: 50%;
				width: vw(10);
				height: vw(45);
				transform: translate(-50%, -50%);
			}
		}

		&__text {
			&--open {
				opacity: 0;
				visibility: hidden;
			}

			&--close {
				opacity: 1;
				visibility: visible;
			}
		}

		&:hover {
			@media (min-width: $sm) {
				width: vw(97, 1920);
				background: #321621;
			}
		}

		@media (min-width: $sm) {
			width: vw(97, 1920);
			background: #29111a;
		}
	}

	.is-show-menu.is-page-digital & {
		&:hover {
			@media (min-width: $sm) {
				background: lighten(#111422, 5%);
			}
		}
	}

	.is-show-menu.is-page-light & {
		&__icon {
			i {
				@media (max-width: $sm - 1) {
					background: $color-white;
				}
			}
		}
	}

	.is-menu-button-transition & {
		transition: 0.25s;
	}

	@media (min-width: $sm) {
		right: 0;
		top: 0;
		width: vw(38);
		height: 100%;
		background: $color-coffee-bean;

		.is-page-digital & {
			background: #111422;
		}
	}

	@media (max-width: $sm - 1) {
		mix-blend-mode: exclusion;

		.is-page-light & {
			mix-blend-mode: normal;
		}
	}
}

.win-rate {
	position: absolute;
	left: 0;
	bottom: vm(53);
	display: flex;
	padding: 0 vm(20);
	width: 100%;
	font-family: $font-family-halvar;
	text-align: center;
	text-transform: uppercase;
	color: $color-white;

	&__number {
		border-radius: vm(42);
		padding: vm(12) vm(15);
		width: vm(100);
		font-size: vm(17);
		line-height: vm(21);
		letter-spacing: 0.05em;
		background: rgba($color-white, 0.07);

		@media (min-width: $sm) {
			border-radius: vw(42);
			padding: vw(26) vw(15);
			width: vw(128);
			font-size: vw(26);
			line-height: vw(31);
		}
	}

	&__text {
		margin-left: vm(6);
		padding: vm(12) vm(24);
		font-weight: 500;
		font-size: vm(9);
		line-height: vm(21);
		letter-spacing: 0.16em;
		background: rgba($color-white, 0.07);

		@media (min-width: $sm) {
			margin-left: vw(6);
			padding: vw(29) vw(40);
			font-weight: 500;
			font-size: vw(12);
			line-height: vw(25);
		}
	}

	.is-state-preloader & {
		opacity: 0;
	}

	@media (min-width: $sm) {
		position: fixed;
		left: vw(34);
		bottom: vw(42);
		padding: 0;
		width: auto;
	}
}

.mCSB_1_scrollbar {
	opacity: 0 !important;
}

.page {
	overflow: hidden;

	.mCSB_inside {
		> .mCSB_container {
			margin-right: 0;
		}
	}

	&__bottom-logo {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: vm(119) 0;
		min-height: 80vh;
		text-align: center;

		svg {
			display: inline-block;
			vertical-align: middle;
		}

		&__top {
			svg {
				width: vm(85);
				height: vm(34);
				fill: $color-laser;
			}
		}

		&__bottom {
			margin-top: vm(9);

			svg {
				width: vm(148);
				height: vm(10);
				fill: $color-white;

				.is-page-light & {
					fill: #131313;
				}
			}
		}

		@media (min-width: $sm) {
			display: none;
		}
	}

	@media (min-width: $sm) {
		// height: 100vh;
	}
}

.page-content {
	position: relative;
	will-change: top;
}

.page-trigger {
}

.noise {
	position: fixed;
	left: 0;
	top: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
	background: url("../images/noise.gif") 50% 50%;
	opacity: 0.05;
}

.cursor {
	position: fixed;
	left: 50%;
	top: -50%;
	z-index: 99;
	display: none;
	border-radius: 50%;
	width: vw(148, 1920);
	height: vw(148, 1920);
	background: $color-white;
	opacity: 0.05;
	transform: translate(-50%, -50%);
	pointer-events: none;

	.is-page-light & {
		background-color: $color-cod-gray !important;
	}

	.is-show-menu & {
		background-color: $color-white !important;
	}
}

.cookies {
	position: fixed;
	right: 0;
	bottom: 0;
	z-index: 45;
	display: none;
	width: 100%;
	color: $color-white;
	background: $color-coffee-bean;

	&__container {
		padding: vm(20);

		@media (min-width: $sm) {
			display: flex;
			align-items: center;
			padding: vw(42, 1920) vw(47, 1920) vw(42, 1920) vw(55, 1920);
		}
	}

	&__text {
		font-weight: 300;
		font-size: vm(12);
		line-height: vm(20);
		letter-spacing: 0.02em;

		@media (min-width: $sm) {
			font-size: vw(17, 1920);
			line-height: vw(29, 1920);
		}
	}

	&__btn {
		flex-shrink: 0;
		margin-top: vm(8);
		text-align: center;

		button {
			display: inline-block;
			vertical-align: middle;
			outline: none;
			border: none;
			padding: vm(12) vm(20);
			font-family: $font-family-halvar;
			font-size: vm(12);
			line-height: vm(16);
			letter-spacing: 0.03em;
			text-transform: uppercase;
			color: $color-heath;
			background: $color-white;
			transition: 0.25s;

			&:hover {
				opacity: 0.7;
			}

			@media (min-width: $sm) {
				padding: vw(21, 1920) vw(40, 1920);
				font-size: vw(14, 1920);
				line-height: vw(16, 1920);
			}
		}

		@media (min-width: $sm) {
			margin: 0 0 0 vw(70, 1920);
		}
	}

	@media (min-width: $sm) {
		right: vw(95, 1920);
		bottom: vw(42, 1920);
		width: vw(1123, 1920);
	}
}

.word-line {
	position: relative;
	z-index: 2;
	display: inline-block;

	svg {
		position: absolute;
		left: 50%;
		top: 50%;
		z-index: -1;
		width: vm(100);
		height: vm(43);
		fill: none;
		transform: translate(-50%, -50%);

		@media (min-width: $sm) {
			width: vf(236);
			height: vf(102);
		}
	}
}

.hidden-mobile {
	@media (max-width: $sm - 1) {
		display: none !important;
	}
}

.hidden-desktop {
	@media (min-width: $sm) {
		display: none !important;
	}
}
