.page-vacancies {
	padding: vm(108) vm(25) 0;

	&__container {
		@media (min-width: $sm) {
			display: flex;
		}
	}

	&__left {
		width: 100%;
	}

	&__right {
		flex-shrink: 0;
		margin-left: vf(150);
		width: vf(736);

		@media (max-width: $sm - 1) {
			display: none;
		}
	}

	&__images {
		img {
			display: block;
			width: 100%;
		}
	}

	&__description {
		margin-bottom: vm(67);

		&__title {
			margin: 0 0 vm(28);
			font-weight: 300;
			font-size: vm(28);
			line-height: vm(39);

			&::after {
				content: "";
				display: inline-block;
				margin-right: vm(-5);
				margin-left: vm(5);
				border-radius: 50%;
				width: vm(6);
				height: vm(6);
				background: $color-laser;

				@media (min-width: $sm) {
					margin-right: vf(-10);
					margin-left: vf(10);
					width: vf(12);
					height: vf(12);
				}
			}

			@media (min-width: $sm) {
				margin: 0 0 vf(78);
				max-width: vf(900);
				font-size: vf(58);
				line-height: vf(81);
			}
		}

		&__text {
			margin-bottom: vm(69);
			font-weight: 300;
			font-size: vm(16);
			line-height: vm(26);
			letter-spacing: 0.02em;
			opacity: 0.9;

			@media (min-width: $sm) {
				margin-bottom: 0;
				max-width: vf(880);
				font-size: vf(28);
				line-height: vf(45);
			}
		}

		&__image {
			margin: 0 vm(-25);

			img {
				width: 100%;
			}

			@media (min-width: $sm) {
				display: none;
			}
		}

		@media (min-width: $sm) {
			margin-bottom: vf(310);
		}
	}

	&__title {
		margin: 0 0 vm(-32) vm(-31);
		font-family: $font-family-halvar;
		font-weight: 300;
		font-size: vm(54);
		line-height: vm(65);
		letter-spacing: -0.04em;
		text-transform: uppercase;
		opacity: 0.07;

		@media (min-width: $sm) {
			margin: 0 0 vf(-96) vf(-8);
			font-size: vf(139);
			line-height: vf(167);
			opacity: 0.03;
		}
	}

	&__list {
		&__item {
			&__city {
				margin-bottom: vm(10);
				font-size: vm(15);
				line-height: vm(18);
				letter-spacing: -0.01em;
				opacity: 0.4;

				@media (min-width: $sm) {
					margin-bottom: vf(13);
					font-size: vf(22);
					line-height: vf(26);
				}
			}

			&__title {
				font-size: vm(20);
				line-height: vm(30);
				letter-spacing: -0.01em;
				opacity: 0.95;

				a {
					position: relative;
					text-decoration: none;
					color: inherit;

					&:hover {
						.page-vacancies__list__item__title__arrow {
							opacity: 1;
							visibility: visible;
						}
					}
				}

				&__arrow {
					position: absolute;
					left: 100%;
					top: 50%;
					margin-left: vf(18);
					opacity: 0;
					visibility: hidden;
					transform: translateY(-50%);
					transition: 0.25s;

					svg {
						display: block;

						&:nth-child(1) {
							width: vf(37);
							height: vf(20);
							stroke: $color-white;
							stroke-width: 2px;
						}

						&:nth-child(2) {
							position: absolute;
							left: 50%;
							top: 50%;
							z-index: -1;
							width: vf(153);
							height: vf(74);
							fill: none;
							transform: translate(-50%, -50%);
						}
					}

					@media (max-width: $sm - 1) {
						display: none;
					}
				}

				@media (min-width: $sm) {
					font-size: vf(30);
					line-height: vf(45);
				}
			}
		}

		&__item + &__item {
			margin-top: vm(56);

			@media (min-width: $sm) {
				margin-top: vf(130);
			}
		}
	}

	.form {
		margin-top: vm(136);

		&__submit {
			margin-top: 0;
		}

		@media (min-width: $sm) {
			margin-top: vf(329);
		}
	}

	&__item {
		&__city {
			margin-bottom: vm(16);
			font-size: vm(16);
			line-height: vm(19);
			letter-spacing: -0.01em;
			opacity: 0.4;

			@media (min-width: $sm) {
				margin-bottom: vf(9);
				font-size: vf(29);
				line-height: vf(35);
			}
		}

		&__title {
			margin: 0 0 vm(23);
			font-weight: 300;
			font-size: vm(28);
			line-height: vm(39);

			@media (min-width: $sm) {
				margin: 0 0 vf(49);
				font-size: vf(88);
				line-height: vf(123);
			}
		}

		&__text {
			margin-bottom: vm(60);
			font-weight: 300;
			font-size: vm(16);
			line-height: vm(26);
			letter-spacing: 0.02em;
			opacity: 0.9;

			@media (min-width: $sm) {
				margin-bottom: vf(136);
				max-width: vf(1300);
				font-size: vf(28);
				line-height: vf(45);
			}
		}

		&__block {
			&__label {
				flex-shrink: 0;
				margin-bottom: vm(20);
				font-size: vm(18);
				line-height: vm(29);

				@media (min-width: $sm) {
					margin: 0 vf(30) 0 0;
					width: vf(250);
					font-size: vf(28);
					line-height: vf(45);
				}
			}

			&__list {
				ul {
					list-style-type: none;
					margin: 0 0 0 vm(-14);
					padding: 0;
					font-weight: 300;
					font-size: vm(14);
					line-height: vm(22);
					letter-spacing: 0.02em;
					opacity: 0.9;

					li {
						position: relative;
						padding-left: vm(14);

						&::before {
							content: "";
							position: absolute;
							left: 0;
							top: vm(8);
							border-radius: 50%;
							width: vm(4);
							height: vm(4);
							background: #e6e6e6;
							opacity: 0.2;

							@media (min-width: $sm) {
								top: vf(12);
								width: vf(4);
								height: vf(4);
							}
						}

						+ li {
							margin-top: vm(22);

							@media (min-width: $sm) {
								margin-top: vf(29);
							}
						}

						@media (min-width: $sm) {
							padding-left: vf(24);
						}
					}

					@media (min-width: $sm) {
						margin: 0;
						font-size: vf(18);
						line-height: vf(29);
					}
				}

				@media (min-width: $sm) {
					padding: vf(9) 0 0;
				}
			}

			@media (min-width: $sm) {
				display: flex;
				max-width: vf(1335);
			}
		}

		.form {
			@media (min-width: $sm) {
				margin-top: vf(233);
				margin-left: vf(305);
			}
		}

		&__block + &__block {
			margin-top: vm(50);

			@media (min-width: $sm) {
				margin-top: vf(140);
			}
		}
	}

	&__contact {
		padding: vm(148) 0 vm(122);
		text-align: center;

		&__value {
			font-family: $font-family-halvar;
			font-weight: 300;
			font-size: vm(21);
			line-height: vm(25);
			letter-spacing: -0.04em;
			text-align: center;
			text-transform: uppercase;

			a {
				text-decoration: none;
				color: inherit;
			}

			@media (min-width: $sm) {
				font-size: vf(99);
				line-height: vf(119);
			}
		}

		@media (min-width: $sm) {
			padding: vf(220) 0;
		}
	}

	@media (min-width: $sm) {
		padding: vf(238) 0 0 vf(55);
	}
}
