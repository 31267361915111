.page-contacts {
	padding: vm(164) vm(25) 0;
	color: $color-white;

	&__description {
		margin-bottom: vm(90);
		font-weight: 300;
		font-size: vm(28);
		line-height: vm(39);

		&::after {
			content: "";
			display: inline-block;
			margin-right: vm(-8);
			margin-left: vm(5);
			border-radius: 50%;
			width: vm(6);
			height: vm(6);
			background: $color-laser;

			@media (min-width: $sm) {
				margin-right: 0;
				margin-left: vf(10);
				width: vf(12);
				height: vf(12);
			}
		}

		@media (min-width: $sm) {
			margin-bottom: vf(90);
			max-width: vf(618);
			font-size: vf(58);
			line-height: vf(81);
		}
	}

	&__title {
		margin: 0 0 vm(-24) vm(-29);
		font-family: $font-family-halvar;
		font-weight: 300;
		font-size: vm(54);
		line-height: vm(65);
		letter-spacing: -0.04em;
		text-transform: uppercase;
		opacity: 0.07;

		@media (min-width: $sm) {
			margin: 0 0 vf(-56) vf(-8);
			font-size: vf(139);
			line-height: vf(167);
			opacity: 0.03;
		}
	}

	&__container {
		@media (min-width: $sm) {
			display: flex;
		}
	}

	&__left {
		margin-bottom: vm(62);

		@media (min-width: $sm) {
			flex-shrink: 0;
			margin: 0 vf(229) 0 0;
			width: vf(885);
		}
	}

	&__right {
		@media (min-width: $sm) {
			width: 100%;
		}
	}

	&__list {
		&__item {
			&__title {
				margin: 0 0 vm(33);
				font-size: vm(18);
				line-height: vm(22);
				letter-spacing: -0.01em;
				opacity: 0.95;

				@media (min-width: $sm) {
					margin: 0 0 vf(44);
					font-size: vf(30);
					line-height: vf(36);
				}
			}

			&__time {
				opacity: 0.45;
			}

			ul {
				list-style-type: none;
				margin: 0;
				padding: 0;
				font-size: vm(15);
				line-height: vm(18);
				letter-spacing: -0.01em;
				opacity: 0.95;

				+ ul {
					margin-top: vm(42);

					@media (min-width: $sm) {
						margin-top: vf(101);
					}
				}

				li {
					+ li {
						margin-top: vm(8);

						@media (min-width: $sm) {
							margin-top: vf(13);
						}
					}

					a {
						border-bottom: solid 2px transparent;
						text-decoration: none;
						color: $color-white;
						transition: 0.25s;

						&:hover {
							border-bottom-color: rgba($color-white, 0.2);
						}
					}
				}

				@media (min-width: $sm) {
					font-size: vf(22);
					line-height: vf(26);
				}
			}

			@media (min-width: $sm) {
				padding: 0 vf(5);
				width: 50%;
			}
		}

		&__item + &__item {
			@media (max-width: $sm - 1) {
				margin-top: vm(94);
			}
		}

		@media (min-width: $sm) {
			display: flex;
			flex-wrap: wrap;
			margin: 0 vf(-5);
		}
	}

	&__map {
		position: relative;
		margin-top: vm(107);

		&__list {
			display: flex;
			list-style-type: none;
			margin: 0 0 vm(16);
			padding: 0;
			font-size: vm(18);
			letter-spacing: -0.01em;

			li {
				+ li {
					margin-left: vm(24);

					@media (min-width: $sm) {
						margin-left: vf(30);
					}
				}

				a {
					text-decoration: none;
					color: $color-white;
					opacity: 0.4;
					transition: 0.25s;

					&:hover,
					&.is-active {
						opacity: 0.95;
					}
				}
			}

			@media (min-width: $sm) {
				position: absolute;
				left: 0;
				top: vf(60);
				z-index: 5;
				margin: 0;
				font-size: vf(24);
			}
		}

		&__self {
			margin: 0 vm(-25) 0;
			height: vm(320);

			> ymaps > ymaps > ymaps {
				> ymaps:nth-child(2) {
					filter: invert(100%);
				}
			}

			@media (min-width: $sm) {
				margin: 0 0 0 vf(-55);
				height: vf(835);
			}
		}

		&__marker-label {
			position: absolute;
			left: 50%;
			top: 40px;
			font-size: 15px;
			white-space: nowrap;
			color: $color-white;
			transform: translateX(-50%);
		}

		@media (min-width: $sm) {
			margin-top: vf(165);
		}
	}

	@media (min-width: $sm) {
		padding: vf(213) vf(200) 0 vf(55);
	}
}
