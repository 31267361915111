.preloader {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 9999;
	width: 100%;
	height: 100%;

	&__background {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: $color-diesel;
		// background: $color-diesel url("../images/noise.png") 50% 50%;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: url("../images/noise.gif") 50% 50%;
			opacity: 0.05;
		}

		.is-page-digital & {
			background: #000313;
		}
	}

	&__dot {
		position: absolute;
		left: 50%;
		top: 50%;
		z-index: 5;
		display: none;
		border-radius: 50%;
		width: vm(100);
		height: vm(100);
		background: $color-white;
		opacity: 0.07;
		transform: translate(-50%, -50%);

		@media (min-width: $sm) {
			top: auto;
			bottom: 50%;
			width: vw(290);
			height: vw(290);
			transform: translate(-50%, 50%);
		}
	}

	&__progress {
		position: absolute;
		left: 50%;
		top: 50%;
		z-index: 5;
		font-family: $font-family-halvar;
		font-size: vm(26);
		line-height: vm(26);
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: $color-white;
		transform: translate(-50%, -50%);

		@media (min-width: $sm) {
			font-size: vw(55);
			line-height: vw(66);
		}
	}
}
