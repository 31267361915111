.header {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 15;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: vm(23) vm(20) 0;
	width: 100%;
	color: $color-white;

	&__logo {
		text-align: center;
		text-decoration: none;
		color: inherit;

		img {
			width: vw(140);
		}

		svg {
			width: vw(140);
			height: vw(49);
			fill: currentColor;
		}

		&__top {
			svg {
				display: inline-block;
				vertical-align: middle;
				width: vm(52);
				height: vm(22);

				@media (min-width: $sm) {
					width: vw(65);
					height: vw(27);
				}
			}

			@media (min-width: $sm) {
				margin-bottom: vw(1);
			}
		}

		&__bottom {
			svg {
				display: inline-block;
				vertical-align: middle;
				width: vw(118);
				height: vw(7);
			}

			@media (max-width: $sm - 1) {
				display: none;
			}
		}
	}

	&__right {
		display: flex;
		align-items: center;

		@media (max-width: $sm - 1) {
			display: none;
		}
	}

	&__lang {
		display: flex;
		list-style-type: none;
		margin: 0;
		padding: 0;
		font-family: $font-family-halvar;
		font-size: vw(11);
		line-height: vw(18);
		letter-spacing: 0.14em;
		text-transform: uppercase;

		li {
			+ li {
				margin-left: vw(7);
			}

			a {
				text-decoration: none;
				color: inherit;
				transition: 0.25s;

				&:hover {
					opacity: 0.6;
				}

				&.is-active {
					opacity: 0.4;
				}
			}
		}
	}

	&__menu {
		margin-left: vw(76);
		font-family: $font-family-halvar;
		font-size: vw(11);
		line-height: vw(18);
		letter-spacing: 0.14em;
		text-transform: uppercase;

		ul {
			display: flex;
			list-style-type: none;
			margin: 0;
			padding: 0;

			li {
				+ li {
					margin-left: vw(30);
				}

				a {
					text-decoration: none;
					color: inherit;
					transition: 0.25s;

					&:hover {
						opacity: 0.6;
					}
				}
			}
		}
	}

	.is-state-preloader & {
		opacity: 0;
	}

	.is-page-light & {
		color: #121212;
	}

	@media (min-width: $sm) {
		padding: vw(40) vw(97) 0 vw(50);
	}

	@media (max-width: $sm - 1) {
		mix-blend-mode: exclusion;

		.is-page-light & {
			mix-blend-mode: normal;
		}
	}
}
